/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2019 */



@font-face {
    font-family: 'akrobatblack';
    src: url('akrobat-black-webfont.woff2') format('woff2'),
         url('akrobat-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatbold';
    src: url('akrobat-bold-webfont.woff2') format('woff2'),
         url('akrobat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatextrabold';
    src: url('akrobat-extrabold-webfont.woff2') format('woff2'),
         url('akrobat-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatextralight';
    src: url('akrobat-extralight-webfont.woff2') format('woff2'),
         url('akrobat-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatlight';
    src: url('akrobat-light-webfont.woff2') format('woff2'),
         url('akrobat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatregular';
    src: url('akrobat-regular-webfont.woff2') format('woff2'),
         url('akrobat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatsemibold';
    src: url('akrobat-semibold-webfont.woff2') format('woff2'),
         url('akrobat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'akrobatthin';
    src: url('akrobat-thin-webfont.woff2') format('woff2'),
         url('akrobat-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}