/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 10, 2019 */



@font-face {
    font-family: 'poppinsbold';
    src: url('poppins-bold-webfont.woff2') format('woff2'),
         url('poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight';
    src: url('poppins-light-webfont.woff2') format('woff2'),
         url('poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsmedium';
    src: url('poppins-medium-webfont.woff2') format('woff2'),
         url('poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsregular';
    src: url('poppins-regular-webfont.woff2') format('woff2'),
         url('poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinssemibold';
    src: url('poppins-semibold-webfont.woff2') format('woff2'),
         url('poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}