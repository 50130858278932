@charset "UTF-8";

@import url(../fonts/Gothan/stylesheet.css);

@import url(../fonts/Poppins/stylesheet.css);

@import url(../fonts/Akrobat/stylesheet.css);

@import url(../css/argon.min.css);

@import url(../css/animate.css);

:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --white: #fff;
  --gray: #8898aa;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #5e72e4;
  --secondary: #f4f5f7;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --light: #adb5bd;
  --dark: #212529;
  --default: #172b4d;
  --white: #fff;
  --neutral: #fff;
  --darker: black;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #5e72e4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #233dd2;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #8898aa;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: #32325d;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 3.3rem;
  font-weight: 600;
  line-height: 1.5;
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.5;
}

.display-3 {
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1.5;
}

.display-4 {
  font-size: 1.6275rem;
  font-weight: 600;
  line-height: 1.5;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8898aa;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #8898aa;
}

code {
  font-size: 87.5%;
  color: #f3a4b5;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1040px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 0.0625rem solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0.125rem solid #dee2e6;
}

.table tbody + tbody {
  border-top: 0.125rem solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0.0625rem solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 0.0625rem solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0.125rem;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2d8f7;
}

.table-hover .table-primary:hover {
  background-color: #bcc5f3;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bcc5f3;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcfcfd;
}

.table-hover .table-secondary:hover {
  background-color: #ededf3;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ededf3;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4f1de;
}

.table-hover .table-success:hover {
  background-color: #afecd2;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #afecd2;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bcf1fb;
}

.table-hover .table-info:hover {
  background-color: #a4ecfa;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a4ecfa;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fed3ca;
}

.table-hover .table-warning:hover {
  background-color: #febeb1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #febeb1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcc7d1;
}

.table-hover .table-danger:hover {
  background-color: #fbafbd;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbafbd;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #e8eaed;
}

.table-hover .table-light:hover {
  background-color: #dadde2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #dadde2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c3;
}

.table-hover .table-dark:hover {
  background-color: #b4b5b6;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b5b6;
}

.table-default,
.table-default > th,
.table-default > td {
  background-color: #bec4cd;
}

.table-hover .table-default:hover {
  background-color: #b0b7c2;
}

.table-hover .table-default:hover > td,
.table-hover .table-default:hover > th {
  background-color: #b0b7c2;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-neutral,
.table-neutral > th,
.table-neutral > td {
  background-color: white;
}

.table-hover .table-neutral:hover {
  background-color: #f2f2f2;
}

.table-hover .table-neutral:hover > td,
.table-hover .table-neutral:hover > th {
  background-color: #f2f2f2;
}

.table-darker,
.table-darker > th,
.table-darker > td {
  background-color: #b8b8b8;
}

.table-hover .table-darker:hover {
  background-color: #ababab;
}

.table-hover .table-darker:hover > td,
.table-hover .table-darker:hover > th {
  background-color: #ababab;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #525f7f;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
  box-shadow: none, none;
}

.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #8898aa;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #525f7f;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(3.625rem + 2px);
  padding: 0.875rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #8898aa;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2dce89;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(45, 206, 137, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #2dce89;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated
  .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #2dce89;
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated
  .custom-select:valid ~ .valid-feedback,
.was-validated
  .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #2dce89;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #2dce89;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #93e7c3;
  border-color: #93e7c3;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #54daa1;
  border-color: #93e7c3;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(45, 206, 137, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2dce89;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(251, 99, 64, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #fb6340;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated
  .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #fb6340;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated
  .custom-select:invalid ~ .invalid-feedback,
.was-validated
  .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #fb6340;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #fb6340;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fec9bd;
  border-color: #fec9bd;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #fc8c72;
  border-color: #fec9bd;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(251, 99, 64, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fb6340;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08), none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary:hover {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #324cdd;
  border-color: #5e72e4;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-secondary:hover {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(244, 245, 247, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #d6dae2;
  border-color: #f4f5f7;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(244, 245, 247, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-success:hover {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(45, 206, 137, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #24a46d;
  border-color: #2dce89;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(45, 206, 137, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-info:hover {
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0da5c0;
  border-color: #11cdef;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fb6340;
  border-color: #fb6340;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-warning:hover {
  color: #fff;
  background-color: #fb6340;
  border-color: #fb6340;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #fb6340;
  border-color: #fb6340;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #fa3a0e;
  border-color: #fb6340;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-danger:hover {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(245, 54, 92, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ec0c38;
  border-color: #f5365c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(245, 54, 92, 0.5);
}

.btn-light {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-light:hover {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(173, 181, 189, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #919ca6;
  border-color: #adb5bd;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(173, 181, 189, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(33, 37, 41, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0c0d;
  border-color: #212529;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(33, 37, 41, 0.5);
}

.btn-default {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-default:hover {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
}

.btn-default:focus,
.btn-default.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
}

.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #0b1526;
  border-color: #172b4d;
}

.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-white:hover {
  color: #212529;
  background-color: white;
  border-color: white;
}

.btn-white:focus,
.btn-white.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: white;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-neutral {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-neutral:hover {
  color: #212529;
  background-color: white;
  border-color: white;
}

.btn-neutral:focus,
.btn-neutral.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-neutral.disabled,
.btn-neutral:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-neutral:not(:disabled):not(.disabled):active,
.btn-neutral:not(:disabled):not(.disabled).active,
.show > .btn-neutral.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: white;
}

.btn-neutral:not(:disabled):not(.disabled):active:focus,
.btn-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-darker {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-darker:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-darker:focus,
.btn-darker.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.btn-darker.disabled,
.btn-darker:disabled {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-darker:not(:disabled):not(.disabled):active,
.btn-darker:not(:disabled):not(.disabled).active,
.show > .btn-darker.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-darker:not(:disabled):not(.disabled):active:focus,
.btn-darker:not(:disabled):not(.disabled).active:focus,
.show > .btn-darker.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.btn-outline-primary {
  color: #5e72e4;
  background-color: transparent;
  background-image: none;
  border-color: #5e72e4;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #5e72e4;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-outline-secondary {
  color: #f4f5f7;
  background-color: transparent;
  background-image: none;
  border-color: #f4f5f7;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(244, 245, 247, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #f4f5f7;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(244, 245, 247, 0.5);
}

.btn-outline-success {
  color: #2dce89;
  background-color: transparent;
  background-image: none;
  border-color: #2dce89;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #2dce89;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #2dce89;
  border-color: #2dce89;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(45, 206, 137, 0.5);
}

.btn-outline-info {
  color: #11cdef;
  background-color: transparent;
  background-image: none;
  border-color: #11cdef;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #11cdef;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #11cdef;
  border-color: #11cdef;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-outline-warning {
  color: #fb6340;
  background-color: transparent;
  background-image: none;
  border-color: #fb6340;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #fb6340;
  border-color: #fb6340;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fb6340;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #fb6340;
  border-color: #fb6340;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-outline-danger {
  color: #f5365c;
  background-color: transparent;
  background-image: none;
  border-color: #f5365c;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(245, 54, 92, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f5365c;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f5365c;
  border-color: #f5365c;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(245, 54, 92, 0.5);
}

.btn-outline-light {
  color: #adb5bd;
  background-color: transparent;
  background-image: none;
  border-color: #adb5bd;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #adb5bd;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5);
}

.btn-outline-dark {
  color: #212529;
  background-color: transparent;
  background-image: none;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5);
}

.btn-outline-default {
  color: #172b4d;
  background-color: transparent;
  background-image: none;
  border-color: #172b4d;
}

.btn-outline-default:hover {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
}

.btn-outline-default:focus,
.btn-outline-default.focus {
  box-shadow: 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-outline-default.disabled,
.btn-outline-default:disabled {
  color: #172b4d;
  background-color: transparent;
}

.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.show > .btn-outline-default.dropdown-toggle {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
}

.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus,
.btn-outline-white.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-neutral {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
}

.btn-outline-neutral:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-neutral:focus,
.btn-outline-neutral.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-neutral.disabled,
.btn-outline-neutral:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-neutral:not(:disabled):not(.disabled):active,
.btn-outline-neutral:not(:disabled):not(.disabled).active,
.show > .btn-outline-neutral.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-neutral:not(:disabled):not(.disabled):active:focus,
.btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-darker {
  color: black;
  background-color: transparent;
  background-image: none;
  border-color: black;
}

.btn-outline-darker:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-outline-darker:focus,
.btn-outline-darker.focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.btn-outline-darker.disabled,
.btn-outline-darker:disabled {
  color: black;
  background-color: transparent;
}

.btn-outline-darker:not(:disabled):not(.disabled):active,
.btn-outline-darker:not(:disabled):not(.disabled).active,
.show > .btn-outline-darker.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-outline-darker:not(:disabled):not(.disabled):active:focus,
.btn-outline-darker:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-darker.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #5e72e4;
  background-color: transparent;
}

.btn-link:hover {
  color: #233dd2;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #8898aa;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.875rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f6f9fc;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5e72e4;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #8898aa;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8898aa;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none;
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(3.625rem + 2px);
  padding: 0.875rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #8898aa;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.125rem;
  left: -1.75rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #fff;
  box-shadow: none;
}

.custom-control-label::after {
  position: absolute;
  top: 0.125rem;
  left: -1.75rem;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5e72e4;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #5e72e4;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(94, 114, 228, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(94, 114, 228, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #5e72e4;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(94, 114, 228, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #8898aa;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%2332325d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}

.custom-select:focus {
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0 rgba(50, 151, 211, 0.5);
}

.custom-select:focus::-ms-value {
  color: #8898aa;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #8898aa;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(3.625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(50, 151, 211, 0.25);
  box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: rgba(50, 151, 211, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  line-height: 1.5;
  color: #8898aa;
  content: "Browse";
  background-color: #fff;
  border-left: 1px solid #cad1d7;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5e72e4;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f7f8fe;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5e72e4;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #f7f8fe;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #5e72e4;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #f7f8fe;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8898aa;
}

.nav-tabs {
  border-bottom: 0.0625rem solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}

.nav-tabs .nav-link {
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #8898aa;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #525f7f;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5e72e4;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml !default;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.95);
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.95);
}

.navbar-dark .navbar-text a {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: rgba(255, 255, 255, 0.65);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 1.25rem;
}

.card-subtitle {
  margin-top: -0.625rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #f6f9fc;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.05);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 0.0625rem) calc(0.25rem - 0.0625rem) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #f6f9fc;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.05);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 0.0625rem) calc(0.25rem - 0.0625rem);
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.25rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 0.0625rem);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 0.0625rem);
  border-top-right-radius: calc(0.25rem - 0.0625rem);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 0.0625rem);
  border-bottom-left-radius: calc(0.25rem - 0.0625rem);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8898aa;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #8898aa;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -0.0625rem;
  line-height: 1.25;
  color: #8898aa;
  background-color: #fff;
  border: 0.0625rem solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #8898aa;
  text-decoration: none;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.page-item.disabled .page-link {
  color: #8898aa;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35rem 0.375rem;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
  border-radius: 10rem;
}

.badge-primary {
  color: #2643e9;
  background-color: rgba(203, 210, 246, 0.5);
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #324cdd;
}

.badge-secondary {
  color: #d3d9e5;
  background-color: rgba(255, 255, 255, 0.5);
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d6dae2;
}

.badge-success {
  color: #1aae6f;
  background-color: rgba(147, 231, 195, 0.5);
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #24a46d;
}

.badge-info {
  color: #03acca;
  background-color: rgba(136, 230, 247, 0.5);
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0da5c0;
}

.badge-warning {
  color: #ff3709;
  background-color: rgba(254, 201, 189, 0.5);
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #fa3a0e;
}

.badge-danger {
  color: #f80031;
  background-color: rgba(251, 175, 190, 0.5);
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ec0c38;
}

.badge-light {
  color: #879cb0;
  background-color: rgba(244, 245, 246, 0.5);
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #919ca6;
}

.badge-dark {
  color: #090c0e;
  background-color: rgba(90, 101, 112, 0.5);
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0a0c0d;
}

.badge-default {
  color: #091428;
  background-color: rgba(52, 98, 175, 0.5);
}

.badge-default[href]:hover,
.badge-default[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0b1526;
}

.badge-white {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5);
}

.badge-white[href]:hover,
.badge-white[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #e6e6e6;
}

.badge-neutral {
  color: #e8e3e3;
  background-color: rgba(255, 255, 255, 0.5);
}

.badge-neutral[href]:hover,
.badge-neutral[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #e6e6e6;
}

.badge-darker {
  color: black;
  background-color: rgba(64, 64, 64, 0.5);
}

.badge-darker[href]:hover,
.badge-darker[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4.5rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.5rem;
  color: inherit;
}

.alert-primary {
  color: #fff;
  background-color: #7889e8;
  border-color: #7889e8;
}

.alert-primary hr {
  border-top-color: #6276e4;
}

.alert-primary .alert-link {
  color: #324cdd;
}

.alert-secondary {
  color: #212529;
  background-color: #f6f7f8;
  border-color: #f6f7f8;
}

.alert-secondary hr {
  border-top-color: #e8eaed;
}

.alert-secondary .alert-link {
  color: #d6dae2;
}

.alert-success {
  color: #fff;
  background-color: #4fd69c;
  border-color: #4fd69c;
}

.alert-success hr {
  border-top-color: #3ad190;
}

.alert-success .alert-link {
  color: #24a46d;
}

.alert-info {
  color: #fff;
  background-color: #37d5f2;
  border-color: #37d5f2;
}

.alert-info hr {
  border-top-color: #1fd0f0;
}

.alert-info .alert-link {
  color: #0da5c0;
}

.alert-warning {
  color: #fff;
  background-color: #fc7c5f;
  border-color: #fc7c5f;
}

.alert-warning hr {
  border-top-color: #fc6846;
}

.alert-warning .alert-link {
  color: #fa3a0e;
}

.alert-danger {
  color: #fff;
  background-color: #f75676;
  border-color: #f75676;
}

.alert-danger hr {
  border-top-color: #f63e62;
}

.alert-danger .alert-link {
  color: #ec0c38;
}

.alert-light {
  color: #fff;
  background-color: #bac1c8;
  border-color: #bac1c8;
}

.alert-light hr {
  border-top-color: #acb4bd;
}

.alert-light .alert-link {
  color: #919ca6;
}

.alert-dark {
  color: #fff;
  background-color: #45484b;
  border-color: #45484b;
}

.alert-dark hr {
  border-top-color: #393b3e;
}

.alert-dark .alert-link {
  color: #0a0c0d;
}

.alert-default {
  color: #fff;
  background-color: #3c4d69;
  border-color: #3c4d69;
}

.alert-default hr {
  border-top-color: #334159;
}

.alert-default .alert-link {
  color: #0b1526;
}

.alert-white {
  color: #212529;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #e6e6e6;
}

.alert-neutral {
  color: #212529;
  background-color: white;
  border-color: white;
}

.alert-neutral hr {
  border-top-color: #f2f2f2;
}

.alert-neutral .alert-link {
  color: #e6e6e6;
}

.alert-darker {
  color: #fff;
  background-color: #292929;
  border-color: #292929;
}

.alert-darker hr {
  border-top-color: #1c1c1c;
}

.alert-darker .alert-link {
  color: black;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5e72e4;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #525f7f;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #525f7f;
  text-decoration: none;
  background-color: #f6f9fc;
}

.list-group-item-action:active {
  color: #525f7f;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;
  background-color: #fff;
  border: 0.0625rem solid #e9ecef;
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #8898aa;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #313b77;
  background-color: #d2d8f7;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #313b77;
  background-color: #bcc5f3;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #313b77;
  border-color: #313b77;
}

.list-group-item-secondary {
  color: #7f7f80;
  background-color: #fcfcfd;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #7f7f80;
  background-color: #ededf3;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f80;
  border-color: #7f7f80;
}

.list-group-item-success {
  color: #176b47;
  background-color: #c4f1de;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #176b47;
  background-color: #afecd2;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #176b47;
  border-color: #176b47;
}

.list-group-item-info {
  color: #096b7c;
  background-color: #bcf1fb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #096b7c;
  background-color: #a4ecfa;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #096b7c;
  border-color: #096b7c;
}

.list-group-item-warning {
  color: #833321;
  background-color: #fed3ca;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #833321;
  background-color: #febeb1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #833321;
  border-color: #833321;
}

.list-group-item-danger {
  color: #7f1c30;
  background-color: #fcc7d1;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7f1c30;
  background-color: #fbafbd;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f1c30;
  border-color: #7f1c30;
}

.list-group-item-light {
  color: #5a5e62;
  background-color: #e8eaed;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #5a5e62;
  background-color: #dadde2;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #5a5e62;
  border-color: #5a5e62;
}

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #111315;
  background-color: #b4b5b6;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #111315;
  border-color: #111315;
}

.list-group-item-default {
  color: #0c1628;
  background-color: #bec4cd;
}

.list-group-item-default.list-group-item-action:hover,
.list-group-item-default.list-group-item-action:focus {
  color: #0c1628;
  background-color: #b0b7c2;
}

.list-group-item-default.list-group-item-action.active {
  color: #fff;
  background-color: #0c1628;
  border-color: #0c1628;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-neutral {
  color: #858585;
  background-color: white;
}

.list-group-item-neutral.list-group-item-action:hover,
.list-group-item-neutral.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-neutral.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-darker {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-darker.list-group-item-action:hover,
.list-group-item-darker.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-darker.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
  text-shadow: none;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.16;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1.25rem;
  margin: -1.25rem -1.25rem -1.25rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.1;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-content {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
  }

  .modal-sm {
    max-width: 380px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
  box-shadow: 0px 0.5rem 2rem 0px rgba(0, 0, 0, 0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 0.75rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.75rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.75rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.75rem 0.75rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: transparent;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.75rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.75rem + 1px) * -1);
  width: 0.75rem;
  height: 1.5rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.75rem 0.75rem 0.75rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: transparent;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.75rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.75rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.75rem 0.75rem 0.75rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: transparent;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1.5rem;
  margin-left: -0.75rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.75rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.75rem + 1px) * -1);
  width: 0.75rem;
  height: 1.5rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.75rem 0 0.75rem 0.75rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: transparent;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #32325d;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.75rem 0.75rem;
  color: #525f7f;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #5e72e4 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #324cdd !important;
}

.bg-secondary {
  background-color: #f4f5f7 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d6dae2 !important;
}

.bg-success {
  background-color: #2dce89 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #24a46d !important;
}

.bg-info {
  background-color: #11cdef !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0da5c0 !important;
}

.bg-warning {
  background-color: #fb6340 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fa3a0e !important;
}

.bg-danger {
  background-color: #f5365c !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ec0c38 !important;
}

.bg-light {
  background-color: #adb5bd !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #919ca6 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0c0d !important;
}

.bg-default {
  background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #0b1526 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-neutral {
  background-color: #fff !important;
}

a.bg-neutral:hover,
a.bg-neutral:focus,
button.bg-neutral:hover,
button.bg-neutral:focus {
  background-color: #e6e6e6 !important;
}

.bg-darker {
  background-color: black !important;
}

a.bg-darker:hover,
a.bg-darker:focus,
button.bg-darker:hover,
button.bg-darker:focus {
  background-color: black !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.0625rem solid #e9ecef !important;
}

.border-top {
  border-top: 0.0625rem solid #e9ecef !important;
}

.border-right {
  border-right: 0.0625rem solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid #e9ecef !important;
}

.border-left {
  border-left: 0.0625rem solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #5e72e4 !important;
}

.border-secondary {
  border-color: #f4f5f7 !important;
}

.border-success {
  border-color: #2dce89 !important;
}

.border-info {
  border-color: #11cdef !important;
}

.border-warning {
  border-color: #fb6340 !important;
}

.border-danger {
  border-color: #f5365c !important;
}

.border-light {
  border-color: #adb5bd !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-default {
  border-color: #172b4d !important;
}

.border-white {
  border-color: #fff !important;
}

.border-neutral {
  border-color: #fff !important;
}

.border-darker {
  border-color: black !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-sm {
  margin: 2rem !important;
}

.mt-sm,
.my-sm {
  margin-top: 2rem !important;
}

.mr-sm,
.mx-sm {
  margin-right: 2rem !important;
}

.mb-sm,
.my-sm {
  margin-bottom: 2rem !important;
}

.ml-sm,
.mx-sm {
  margin-left: 2rem !important;
}

.m-md {
  margin: 4rem !important;
}

.mt-md,
.my-md {
  margin-top: 4rem !important;
}

.mr-md,
.mx-md {
  margin-right: 4rem !important;
}

.mb-md,
.my-md {
  margin-bottom: 4rem !important;
}

.ml-md,
.mx-md {
  margin-left: 4rem !important;
}

.m-lg {
  margin: 6rem !important;
}

.mt-lg,
.my-lg {
  margin-top: 6rem !important;
}

.mr-lg,
.mx-lg {
  margin-right: 6rem !important;
}

.mb-lg,
.my-lg {
  margin-bottom: 6rem !important;
}

.ml-lg,
.mx-lg {
  margin-left: 6rem !important;
}

.m-xl {
  margin: 8rem !important;
}

.mt-xl,
.my-xl {
  margin-top: 8rem !important;
}

.mr-xl,
.mx-xl {
  margin-right: 8rem !important;
}

.mb-xl,
.my-xl {
  margin-bottom: 8rem !important;
}

.ml-xl,
.mx-xl {
  margin-left: 8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-sm {
  padding: 2rem !important;
}

.pt-sm,
.py-sm {
  padding-top: 2rem !important;
}

.pr-sm,
.px-sm {
  padding-right: 2rem !important;
}

.pb-sm,
.py-sm {
  padding-bottom: 2rem !important;
}

.pl-sm,
.px-sm {
  padding-left: 2rem !important;
}

.p-md {
  padding: 4rem !important;
}

.pt-md,
.py-md {
  padding-top: 4rem !important;
}

.pr-md,
.px-md {
  padding-right: 4rem !important;
}

.pb-md,
.py-md {
  padding-bottom: 4rem !important;
}

.pl-md,
.px-md {
  padding-left: 4rem !important;
}

.p-lg {
  padding: 6rem !important;
}

.pt-lg,
.py-lg {
  padding-top: 6rem !important;
}

.pr-lg,
.px-lg {
  padding-right: 6rem !important;
}

.pb-lg,
.py-lg {
  padding-bottom: 6rem !important;
}

.pl-lg,
.px-lg {
  padding-left: 6rem !important;
}

.p-xl {
  padding: 8rem !important;
}

.pt-xl,
.py-xl {
  padding-top: 8rem !important;
}

.pr-xl,
.px-xl {
  padding-right: 8rem !important;
}

.pb-xl,
.py-xl {
  padding-bottom: 8rem !important;
}

.pl-xl,
.px-xl {
  padding-left: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-sm {
    margin: 2rem !important;
  }

  .mt-sm-sm,
  .my-sm-sm {
    margin-top: 2rem !important;
  }

  .mr-sm-sm,
  .mx-sm-sm {
    margin-right: 2rem !important;
  }

  .mb-sm-sm,
  .my-sm-sm {
    margin-bottom: 2rem !important;
  }

  .ml-sm-sm,
  .mx-sm-sm {
    margin-left: 2rem !important;
  }

  .m-sm-md {
    margin: 4rem !important;
  }

  .mt-sm-md,
  .my-sm-md {
    margin-top: 4rem !important;
  }

  .mr-sm-md,
  .mx-sm-md {
    margin-right: 4rem !important;
  }

  .mb-sm-md,
  .my-sm-md {
    margin-bottom: 4rem !important;
  }

  .ml-sm-md,
  .mx-sm-md {
    margin-left: 4rem !important;
  }

  .m-sm-lg {
    margin: 6rem !important;
  }

  .mt-sm-lg,
  .my-sm-lg {
    margin-top: 6rem !important;
  }

  .mr-sm-lg,
  .mx-sm-lg {
    margin-right: 6rem !important;
  }

  .mb-sm-lg,
  .my-sm-lg {
    margin-bottom: 6rem !important;
  }

  .ml-sm-lg,
  .mx-sm-lg {
    margin-left: 6rem !important;
  }

  .m-sm-xl {
    margin: 8rem !important;
  }

  .mt-sm-xl,
  .my-sm-xl {
    margin-top: 8rem !important;
  }

  .mr-sm-xl,
  .mx-sm-xl {
    margin-right: 8rem !important;
  }

  .mb-sm-xl,
  .my-sm-xl {
    margin-bottom: 8rem !important;
  }

  .ml-sm-xl,
  .mx-sm-xl {
    margin-left: 8rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-sm {
    padding: 2rem !important;
  }

  .pt-sm-sm,
  .py-sm-sm {
    padding-top: 2rem !important;
  }

  .pr-sm-sm,
  .px-sm-sm {
    padding-right: 2rem !important;
  }

  .pb-sm-sm,
  .py-sm-sm {
    padding-bottom: 2rem !important;
  }

  .pl-sm-sm,
  .px-sm-sm {
    padding-left: 2rem !important;
  }

  .p-sm-md {
    padding: 4rem !important;
  }

  .pt-sm-md,
  .py-sm-md {
    padding-top: 4rem !important;
  }

  .pr-sm-md,
  .px-sm-md {
    padding-right: 4rem !important;
  }

  .pb-sm-md,
  .py-sm-md {
    padding-bottom: 4rem !important;
  }

  .pl-sm-md,
  .px-sm-md {
    padding-left: 4rem !important;
  }

  .p-sm-lg {
    padding: 6rem !important;
  }

  .pt-sm-lg,
  .py-sm-lg {
    padding-top: 6rem !important;
  }

  .pr-sm-lg,
  .px-sm-lg {
    padding-right: 6rem !important;
  }

  .pb-sm-lg,
  .py-sm-lg {
    padding-bottom: 6rem !important;
  }

  .pl-sm-lg,
  .px-sm-lg {
    padding-left: 6rem !important;
  }

  .p-sm-xl {
    padding: 8rem !important;
  }

  .pt-sm-xl,
  .py-sm-xl {
    padding-top: 8rem !important;
  }

  .pr-sm-xl,
  .px-sm-xl {
    padding-right: 8rem !important;
  }

  .pb-sm-xl,
  .py-sm-xl {
    padding-bottom: 8rem !important;
  }

  .pl-sm-xl,
  .px-sm-xl {
    padding-left: 8rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-sm {
    margin: 2rem !important;
  }

  .mt-md-sm,
  .my-md-sm {
    margin-top: 2rem !important;
  }

  .mr-md-sm,
  .mx-md-sm {
    margin-right: 2rem !important;
  }

  .mb-md-sm,
  .my-md-sm {
    margin-bottom: 2rem !important;
  }

  .ml-md-sm,
  .mx-md-sm {
    margin-left: 2rem !important;
  }

  .m-md-md {
    margin: 4rem !important;
  }

  .mt-md-md,
  .my-md-md {
    margin-top: 4rem !important;
  }

  .mr-md-md,
  .mx-md-md {
    margin-right: 4rem !important;
  }

  .mb-md-md,
  .my-md-md {
    margin-bottom: 4rem !important;
  }

  .ml-md-md,
  .mx-md-md {
    margin-left: 4rem !important;
  }

  .m-md-lg {
    margin: 6rem !important;
  }

  .mt-md-lg,
  .my-md-lg {
    margin-top: 6rem !important;
  }

  .mr-md-lg,
  .mx-md-lg {
    margin-right: 6rem !important;
  }

  .mb-md-lg,
  .my-md-lg {
    margin-bottom: 6rem !important;
  }

  .ml-md-lg,
  .mx-md-lg {
    margin-left: 6rem !important;
  }

  .m-md-xl {
    margin: 8rem !important;
  }

  .mt-md-xl,
  .my-md-xl {
    margin-top: 8rem !important;
  }

  .mr-md-xl,
  .mx-md-xl {
    margin-right: 8rem !important;
  }

  .mb-md-xl,
  .my-md-xl {
    margin-bottom: 8rem !important;
  }

  .ml-md-xl,
  .mx-md-xl {
    margin-left: 8rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-sm {
    padding: 2rem !important;
  }

  .pt-md-sm,
  .py-md-sm {
    padding-top: 2rem !important;
  }

  .pr-md-sm,
  .px-md-sm {
    padding-right: 2rem !important;
  }

  .pb-md-sm,
  .py-md-sm {
    padding-bottom: 2rem !important;
  }

  .pl-md-sm,
  .px-md-sm {
    padding-left: 2rem !important;
  }

  .p-md-md {
    padding: 4rem !important;
  }

  .pt-md-md,
  .py-md-md {
    padding-top: 4rem !important;
  }

  .pr-md-md,
  .px-md-md {
    padding-right: 4rem !important;
  }

  .pb-md-md,
  .py-md-md {
    padding-bottom: 4rem !important;
  }

  .pl-md-md,
  .px-md-md {
    padding-left: 4rem !important;
  }

  .p-md-lg {
    padding: 6rem !important;
  }

  .pt-md-lg,
  .py-md-lg {
    padding-top: 6rem !important;
  }

  .pr-md-lg,
  .px-md-lg {
    padding-right: 6rem !important;
  }

  .pb-md-lg,
  .py-md-lg {
    padding-bottom: 6rem !important;
  }

  .pl-md-lg,
  .px-md-lg {
    padding-left: 6rem !important;
  }

  .p-md-xl {
    padding: 8rem !important;
  }

  .pt-md-xl,
  .py-md-xl {
    padding-top: 8rem !important;
  }

  .pr-md-xl,
  .px-md-xl {
    padding-right: 8rem !important;
  }

  .pb-md-xl,
  .py-md-xl {
    padding-bottom: 8rem !important;
  }

  .pl-md-xl,
  .px-md-xl {
    padding-left: 8rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-sm {
    margin: 2rem !important;
  }

  .mt-lg-sm,
  .my-lg-sm {
    margin-top: 2rem !important;
  }

  .mr-lg-sm,
  .mx-lg-sm {
    margin-right: 2rem !important;
  }

  .mb-lg-sm,
  .my-lg-sm {
    margin-bottom: 2rem !important;
  }

  .ml-lg-sm,
  .mx-lg-sm {
    margin-left: 2rem !important;
  }

  .m-lg-md {
    margin: 4rem !important;
  }

  .mt-lg-md,
  .my-lg-md {
    margin-top: 4rem !important;
  }

  .mr-lg-md,
  .mx-lg-md {
    margin-right: 4rem !important;
  }

  .mb-lg-md,
  .my-lg-md {
    margin-bottom: 4rem !important;
  }

  .ml-lg-md,
  .mx-lg-md {
    margin-left: 4rem !important;
  }

  .m-lg-lg {
    margin: 6rem !important;
  }

  .mt-lg-lg,
  .my-lg-lg {
    margin-top: 6rem !important;
  }

  .mr-lg-lg,
  .mx-lg-lg {
    margin-right: 6rem !important;
  }

  .mb-lg-lg,
  .my-lg-lg {
    margin-bottom: 6rem !important;
  }

  .ml-lg-lg,
  .mx-lg-lg {
    margin-left: 6rem !important;
  }

  .m-lg-xl {
    margin: 8rem !important;
  }

  .mt-lg-xl,
  .my-lg-xl {
    margin-top: 8rem !important;
  }

  .mr-lg-xl,
  .mx-lg-xl {
    margin-right: 8rem !important;
  }

  .mb-lg-xl,
  .my-lg-xl {
    margin-bottom: 8rem !important;
  }

  .ml-lg-xl,
  .mx-lg-xl {
    margin-left: 8rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-sm {
    padding: 2rem !important;
  }

  .pt-lg-sm,
  .py-lg-sm {
    padding-top: 2rem !important;
  }

  .pr-lg-sm,
  .px-lg-sm {
    padding-right: 2rem !important;
  }

  .pb-lg-sm,
  .py-lg-sm {
    padding-bottom: 2rem !important;
  }

  .pl-lg-sm,
  .px-lg-sm {
    padding-left: 2rem !important;
  }

  .p-lg-md {
    padding: 4rem !important;
  }

  .pt-lg-md,
  .py-lg-md {
    padding-top: 4rem !important;
  }

  .pr-lg-md,
  .px-lg-md {
    padding-right: 4rem !important;
  }

  .pb-lg-md,
  .py-lg-md {
    padding-bottom: 4rem !important;
  }

  .pl-lg-md,
  .px-lg-md {
    padding-left: 4rem !important;
  }

  .p-lg-lg {
    padding: 6rem !important;
  }

  .pt-lg-lg,
  .py-lg-lg {
    padding-top: 6rem !important;
  }

  .pr-lg-lg,
  .px-lg-lg {
    padding-right: 6rem !important;
  }

  .pb-lg-lg,
  .py-lg-lg {
    padding-bottom: 6rem !important;
  }

  .pl-lg-lg,
  .px-lg-lg {
    padding-left: 6rem !important;
  }

  .p-lg-xl {
    padding: 8rem !important;
  }

  .pt-lg-xl,
  .py-lg-xl {
    padding-top: 8rem !important;
  }

  .pr-lg-xl,
  .px-lg-xl {
    padding-right: 8rem !important;
  }

  .pb-lg-xl,
  .py-lg-xl {
    padding-bottom: 8rem !important;
  }

  .pl-lg-xl,
  .px-lg-xl {
    padding-left: 8rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-sm {
    margin: 2rem !important;
  }

  .mt-xl-sm,
  .my-xl-sm {
    margin-top: 2rem !important;
  }

  .mr-xl-sm,
  .mx-xl-sm {
    margin-right: 2rem !important;
  }

  .mb-xl-sm,
  .my-xl-sm {
    margin-bottom: 2rem !important;
  }

  .ml-xl-sm,
  .mx-xl-sm {
    margin-left: 2rem !important;
  }

  .m-xl-md {
    margin: 4rem !important;
  }

  .mt-xl-md,
  .my-xl-md {
    margin-top: 4rem !important;
  }

  .mr-xl-md,
  .mx-xl-md {
    margin-right: 4rem !important;
  }

  .mb-xl-md,
  .my-xl-md {
    margin-bottom: 4rem !important;
  }

  .ml-xl-md,
  .mx-xl-md {
    margin-left: 4rem !important;
  }

  .m-xl-lg {
    margin: 6rem !important;
  }

  .mt-xl-lg,
  .my-xl-lg {
    margin-top: 6rem !important;
  }

  .mr-xl-lg,
  .mx-xl-lg {
    margin-right: 6rem !important;
  }

  .mb-xl-lg,
  .my-xl-lg {
    margin-bottom: 6rem !important;
  }

  .ml-xl-lg,
  .mx-xl-lg {
    margin-left: 6rem !important;
  }

  .m-xl-xl {
    margin: 8rem !important;
  }

  .mt-xl-xl,
  .my-xl-xl {
    margin-top: 8rem !important;
  }

  .mr-xl-xl,
  .mx-xl-xl {
    margin-right: 8rem !important;
  }

  .mb-xl-xl,
  .my-xl-xl {
    margin-bottom: 8rem !important;
  }

  .ml-xl-xl,
  .mx-xl-xl {
    margin-left: 8rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-sm {
    padding: 2rem !important;
  }

  .pt-xl-sm,
  .py-xl-sm {
    padding-top: 2rem !important;
  }

  .pr-xl-sm,
  .px-xl-sm {
    padding-right: 2rem !important;
  }

  .pb-xl-sm,
  .py-xl-sm {
    padding-bottom: 2rem !important;
  }

  .pl-xl-sm,
  .px-xl-sm {
    padding-left: 2rem !important;
  }

  .p-xl-md {
    padding: 4rem !important;
  }

  .pt-xl-md,
  .py-xl-md {
    padding-top: 4rem !important;
  }

  .pr-xl-md,
  .px-xl-md {
    padding-right: 4rem !important;
  }

  .pb-xl-md,
  .py-xl-md {
    padding-bottom: 4rem !important;
  }

  .pl-xl-md,
  .px-xl-md {
    padding-left: 4rem !important;
  }

  .p-xl-lg {
    padding: 6rem !important;
  }

  .pt-xl-lg,
  .py-xl-lg {
    padding-top: 6rem !important;
  }

  .pr-xl-lg,
  .px-xl-lg {
    padding-right: 6rem !important;
  }

  .pb-xl-lg,
  .py-xl-lg {
    padding-bottom: 6rem !important;
  }

  .pl-xl-lg,
  .px-xl-lg {
    padding-left: 6rem !important;
  }

  .p-xl-xl {
    padding: 8rem !important;
  }

  .pt-xl-xl,
  .py-xl-xl {
    padding-top: 8rem !important;
  }

  .pr-xl-xl,
  .px-xl-xl {
    padding-right: 8rem !important;
  }

  .pb-xl-xl,
  .py-xl-xl {
    padding-bottom: 8rem !important;
  }

  .pl-xl-xl,
  .px-xl-xl {
    padding-left: 8rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #5e72e4 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #324cdd !important;
}

.text-secondary {
  color: #f4f5f7 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #d6dae2 !important;
}

.text-success {
  color: #2dce89 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #24a46d !important;
}

.text-info {
  color: #11cdef !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0da5c0 !important;
}

.text-warning {
  color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fa3a0e !important;
}

.text-danger {
  color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ec0c38 !important;
}

.text-light {
  color: #adb5bd !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #919ca6 !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #0a0c0d !important;
}

.text-default {
  color: #172b4d !important;
}

a.text-default:hover,
a.text-default:focus {
  color: #0b1526 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-neutral {
  color: #fff !important;
}

a.text-neutral:hover,
a.text-neutral:focus {
  color: #e6e6e6 !important;
}

.text-darker {
  color: black !important;
}

a.text-darker:hover,
a.text-darker:focus {
  color: black !important;
}

.text-body {
  color: #525f7f !important;
}

.text-muted {
  color: #8898aa !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 0.0625rem solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 0.0625rem solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border-radius: .1em;
  border: solid .08em #eee;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

body {
  overflow-x: hidden;
  width: 100vw;
  margin-left: 0 !important;
  margin: 0 !important;
  margin-top: 105px !important;
  background: white;
}

header {
  position: relative;
}

.overlay-search {
  display: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.row {
  margin: 0 !important;
}

.align-in-v {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #6b9737;
}

h1 {
  line-height: 36px;
  display: block;
  width: 100%;
  margin-bottom: 0;
  font-family: "oxygenbold", sans-serif;
  font-size: 52px;
  color: white;
}

h6,
h4,
h5 {
  font-family: "Gotham-bold", sans-serif;
}

p {
  line-height: 18px;
  font-family: "oxygenlight", sans-serif;
  font-size: 16px;
  color: #999;
}

.ls-1 {
  color: #8898aa93 !important;
}

.text-bluebaby small {
  color: #46bdd7 !important;
  font-family: "Gotham-bold", sans-serif;
  font-size: 100% !important;
}

small {
  font-family: "Gotham-book", sans-serif;
  color: #8898aa !important;
}

.gotham-font {
  font-family: "Gotham-book", sans-serif;
}

b,
strong {
  font-weight: bold;
}

.text-404 {
  text-shadow: 0 4px 0px #36a2bb, 0 10px 7px rgba(0, 0, 0, 0.35);
  color: #46bdd7;
  font-size: 12em;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 0.6em;
}

.ctn-banner {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #e8e8e8;
}

.slick-list {
  width: 100%;
  height: 100% !important;
}

.slick-track {
  height: 100% !important;
}

.i-bann {
  height: 100% !important;
}

.ctn-side-menu {
  position: fixed;
  left: -100%;
  box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
  top: 0;
  width: 50%;
  height: 100%;
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  transition: left 0.2s;
}

.sidemenu-header {
  width: 100%;
  display: inline-block;
  background: #6b9737;
  padding: 20px 20px;
  color: white;
}

.sidemenu-header .cart-infs {
  color: white;
  margin-top: 2px;
}

.sidemenu-header svg {
  width: 36px;
  height: 36px;
  margin-top: 0;
}

.sidemenu-header svg path {
  fill: white !important;
}

.content-sidemenu .dropdown-item {
  color: #6b9737;
  font-family: "Gotham-bold", Sans-Serif;
  font-size: 15px;
  font-weight: 300;
}

.content-sidemenu .dropdown-item:active {
  background: #f7fafc !important;
}

.sidemenu-open {
  left: 0 !important;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
}

.sidemenu-open:after {
  position: absolute;
  content: "Fechar";
  z-index: 99999;
  top: 20px;
  left: calc(105%);
  display: block;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  font-weight: bold;
  font-size: 18px;
}

.sidemenu-open:before {
  position: fixed;
  content: " ";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.wr-side-menu {
  overflow: scroll;
  width: 100%;
  height: 100%;
  background: white;
  padding: 0 0 0 0;
  position: relative;
  z-index: 99999;
}

.dr-it-gray {
  position: relative;
  background: #f1f1f1 !important;
  color: #333 !important;
}

.dr-it-gray i {
  margin-right: 15px;
  font-size: 19px;
}

.dr-it-gray .fa-angle-down {
  position: absolute;
  right: 20px;
  top: 18px;
}

.wr-submenu ul {
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px !important;
  background: #eaeaea !important;
  margin-bottom: 0 !important;
}

.wr-submenu ul a {
  background: #eaeaea !important;
}

.sr-sm {
  margin-top: 15px;
  display: block;
  padding: 0 15px;
}

.content-sidemenu {
  padding-bottom: 20px;
}

.ctn-siderigth-menu {
  position: fixed;
  right: -100%;
  box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
  top: 0;
  width: 50%;
  height: 100%;
  -webkit-transition: right 0.2s;
  -moz-transition: right 0.2s;
  transition: right 0.2s;
}

.sidemenuright-open {
  right: 0 !important;
  -webkit-transition: right 0.3s;
  -moz-transition: right 0.3s;
  transition: right 0.3s;
}

.sidemenuright-open:after {
  position: absolute;
  content: "Fechar";
  z-index: 99999;
  top: 20px;
  right: calc(105%);
  display: block;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  font-weight: bold;
  font-size: 18px;
}

.sidemenuright-open:before {
  position: fixed;
  content: " ";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.sidemenuright-header {
  width: 100%;
  display: inline-block;
  font-family: "Gotham-bold", Sans-Serif;
  background: #6b9737;
  text-align: center;
  padding: 15px 15px;
  color: white;
}

@media (min-width: 993px) {
  .ctn-side-menu,
  .ctn-siderigth-menu {
    display: none;
  }
}

@media (max-width: 620px) {
  .ctn-side-menu,
  .ctn-siderigth-menu {
    width: 65%;
  }
}

@media (max-width: 480px) {
  .ctn-side-menu,
  .ctn-siderigth-menu {
    width: 75%;
  }
}

@media (max-width: 340px) {
  .ctn-side-menu,
  .ctn-siderigth-menu {
    width: 85%;
  }
}

.ctn-top-header {
  position: fixed;
  height: 124px;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 14px rgba(0, 0, 0, 0.88);
  z-index: 13;
  background-color: #fe8100;
}

.ctn-header {
  position: relative;
  z-index: 12;
  background-color: #fe8100;
  height: 124px;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ctn-top-header .row {
  justify-content: flex-end;
  align-items: center;
}

.ctn-top-head-mobile .btn-mult-content {
  padding: 0.35rem 0.8rem;
}

.ctn-top-head-mobile .box-cart {
  margin: 0 !important;
  height: auto !important;
}

.btn-cart-mob {
  margin-right: 10px;
  position: relative;
  margin-top: -6px;
}

.btn-cart-mob div {
  position: absolute;
  padding: 0 5px;
  border: 3px solid #fe8100;
  min-width: 29px;
  height: 29px;
  border-radius: 16px;
  background: #46bdd7;
  bottom: -8px;
  right: 50%;
}

.btn-cart-mob div span {
  position: relative;
  font-family: "Gotham-bold", Arial, sans-serif;
  width: 100%;
  text-align: center;
  display: block;
  line-height: 25px;
  color: white;
  font-size: 14px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn-user-mob i,
.btn-cart-mob i {
  font-size: 32px;
  color: #6b9737;
}

@media (max-width: 480px) {
  .btn-search-mob {
    width: 35px !important;
    height: 35px !important;
  }

  .btn-search-mob i {
    font-size: 16px !important;
  }

  .btn-user-mob i,
  .btn-cart-mob i {
    font-size: 24px;
    color: #6b9737;
  }

  .hamb-wrapper p {
    font-size: 11px !important;
  }

  .col-btns-mob {
    padding-left: 0 !important;
  }

  .box-menu {
    padding-right: 1rem !important;
    padding-left: 20px !important;
  }
}

.box-logo {
  width: 300px;
  height: 100%;
}

.box-logo img {
  position: absolute;
  top: 50%;
  left: 0;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 95%;
}

.btn-rs {
  margin: 0 3px;
  color: white;
  font-size: 29px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.box-cart {
  position: relative;
  cursor: pointer;
  height: 50px;
  float: right;
  margin: 0 0 0 15px;
}

.cart-img {
  float: left;
  margin-top: 3px;
}

.wrapp-rs {
  margin-right: 10px;
}

.cart-infs {
  position: relative;
  margin-top: 0;
  height: 29px;
  margin-bottom: 0;
  margin-left: 10px;
  line-height: 14px;
  text-align: left;
  float: left;
  color: #212121;
  font-family: "Gotham-book", sans-serif;
}

.num-itens {
  font-family: "Gotham-bold", sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 1px;
}

.cart-infs .total {
  font-size: 13px;
}

.badge-danger {
  color: #f80031;
  background-color: #fbafbe;
}

.btn .badge-floating {
  position: absolute;
  left: -15px;
  top: 0;
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  transform: translateY(-40%);
  border: 3px solid #fff;
  width: 25px;
  padding: 0;
  height: 25px;
  font-size: 0.79rem;
  margin: 0 !important;
}

.btn .badge-floating i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar-brand {
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  color: white !important;
  height: 124px;
  line-height: 124px;
  font-family: "Gotham-bold", sans-serif;
  margin-right: 30px !important;
  font-size: 24px !important;
}

.navbar-brand:not(.selected):hover {
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.ctn-header .nav-item {
  margin-right: 0 !important;
  padding: 0 15px;
  border-right: 1px solid #6296ca;
}

.ctn-header .nav-item:last-child {
  border-right: none;
}

.ctn-header .nav-item .nav-link {
  font-family: "Gotham-book", sans-serif;
  font-size: 18px !important;
}

.line-selector {
  width: 20px;
  opacity: 0;
  bottom: 0;
  height: 6px;
  background: #46bdd7;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.line-selector:before {
  position: absolute;
  z-index: -5;
  bottom: 100%;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  transform: translate(-50%, 0.3rem) rotate(-45deg);
  border-radius: 0.1rem;
  background: #46bdd7;
  box-shadow: none;
}

.ctn-header .navbar-brand.selected .line-selector,
.ctn-header .navbar-brand .line-selector:hover {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  opacity: 1;
  width: 80px;
}

.ctn-footer {
  height: 490px;
  background-color: #222;
}

.ctn-fot_rights {
  height: auto;
  background-color: #6b9737;
}

.t-foot {
  position: relative;
  margin: 0;
  font-size: 13px;
  color: white;
  text-align: center;
}

.ps-lk {
  display: block;
  position: absolute;
  right: 0;
  width: 20px;
  height: 19px;
  background: url("./../../public/img/_layout/icone_branco.png") center center no-repeat;
  background-size: contain;
}

.cont-fot {
  opacity: 0.5;
}

.ctn-fot_rights {
  position: relative;
  z-index: 2;
  padding: 26px 0;
  background: white;
}

.text-copy {
  position: relative;
  padding-bottom: 5px;
  margin: 0 !important;
  width: 100%;
  font-size: 18px;
  color: #3f2814;
  text-align: center;
}

.ic-ps {
  position: relative;
  margin-left: 10px;
  top: 5px;
  display: inline-block;
  width: 21px;
  height: 20px;
  background: url("./../../public/img/_layout/ps_logo.png") no-repeat;
  background-size: contain;
}

.align-in-v {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.align-in-vh {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.foot {
  width: 100%;
  background: #fe8100;
  padding: 110px 0 90px 0;
}

.rs-footer {
  position: absolute;
  width: 202px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  padding: 10px 15px;
  background: #fe8100;
}

/*
.line-back-rs {
  position: relative;
  margin-bottom: 60px;
  display: inline-block;
  width: 483px;
  background: url("../img/_layout/point_ic.png") repeat-x;
  height: 4px;
  max-width: 100%;
}*/

.box-inf-foot.text-right {
  position: relative;
}

.box-inf-foot.text-left:before {
  position: absolute;
  content: " ";
  display: block;
  left: 0;
  width: 1px;
  height: 100%;
  top: 0;
  background: white;
}

.box-inf-foot {
  color: white;
  font-family: "Gotham-book", sans-serif;
  padding-top: 25px;
  padding-bottom: 25px;
}

.img-infs-foot {
  float: left;
  margin-top: 40px;
  position: relative;
  max-width: 100%;
}

.img-logo-foot {
  position: relative;
  max-width: 95%;
  margin-bottom: 20px;
  top: 35px;
}

.wrapp-top_fot {
  position: relative;
  width: 100%;
  height: 66px;
  margin-top: 50px;
}

.box-logo_fot,
.line-fot {
  float: left;
  width: 33.33333333333333%;
}

.box-logo_fot {
  position: relative;
  text-align: center;
}

.line-fot {
  position: relative;
  height: 2px;
  background-color: #444;
}

.wrpp-main_fot {
  margin-top: 55px;
  margin-bottom: 40px;
}

.group-min {
  float: left;
  width: 40%;
  margin-top: 20px;
}

.box-inst {
  float: left;
  width: 50%;
}

.box-duv {
  float: left;
  width: 50%;
}

.title-foot {
  font-family: "Myriad Pro", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 15px;
  color: #bd3b3e;
  font-size: 18px;
}

.nav-foot {
  width: 100%;
}

.it-foot {
  display: block;
  font-family: Arial, sans-serif;
  color: #202020;
  font-size: 15px;
  width: 100%;
  margin-top: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.it-foot:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  color: #bd3b3e;
  text-decoration: none;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
}

.lk-foot {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.newsletter-box {
  margin-bottom: 60px;
}

.newsletter-text {
  position: relative;
  transform: translateY(4px);
  -webkit-transform: translateY(4px);
  color: white;
  display: block;
  text-align: left;
  margin-right: 20px;
  font-family: "Gotham-book", Sans-Serif;
  font-size: 22px;
}

.newsletter-text span {
  font-weight: bold;
}

.form-newsletter {
  position: relative;
  width: 495px;
  max-width: 100%;
  height: 46px;
  border-radius: 23px;
  background: white;
  display: inline-block;
}

.form-newsletter input {
  width: 100%;
  height: 100%;
  padding-left: 20px !important;
  padding-right: 140px !important;
  color: #aaa;
  background: transparent !important;
  float: none !important;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  font-size: 16px;
  border: none !important;
}

.form-newsletter input::placeholder {
  color: #aaa;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.btn-rounded-form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  display: inline-block;
  padding: 8px 25px 6px 25px;
  color: white !important;
  font-family: "Gotham-bold", Sans-Serif;
  text-decoration: none !important;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 19px;
  margin: 0 5px;
  background: #46bdd7;
}

.btn-rounded-form:hover {
  border-bottom: 2px solid #46bdd7;
}

.btn-rounded-form:active {
  transform: translateY(-50%) scale(0.9);
  transition: 0.1s;
  border-bottom: none;
  border-top: 2px solid #46bdd7;
}

.menu-footer {
  transform: translateX(80px);
  width: 100%;
  margin: 0 auto !important;
}

.menu-footer a {
  font-family: "Geomanist", sans-serif;
  font-size: 13px;
  display: block;
  margin-bottom: 12px;
  color: white;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  text-align: left;
}

.menu-footer a:hover {
  color: rgba(236, 233, 233, 0.788);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.gray-border-serrated {
  position: relative;
}

.gray-border-serrated-bottom {
  position: relative;
}

*.btn:active,
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
}

.btn-round {
  border-radius: 22px;
  font-size: 15px;
  font-family: "Gotham-book", sans-serif;
}

.btn-rs-circle {
  width: 34px;
  border: 1px solid transparent;
  height: 34px;
  background: white;
  margin: 0 10px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.15s;
  -webkit-transition: 0.15s;
}

.btn-rs-circle:hover {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.41), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-rs-circle:active {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  border-color: white;
  box-shadow: none;
  background: #d3e3ed;
  transition: 0.1s;
  -webkit-transition: 0.1s;
}

.btn-rs-circle .fab {
  display: block;
  position: relative;
  margin-left: -1px;
  color: #fe8100;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.btn:not(.btn-link):not(.btn-outline-default):not(.btn-lg) {
  border: 2px solid transparent;
  padding: 0.35rem 1.25rem;
  outline: none !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-bluebaby {
  background: #46bdd7;
  color: #fff;
}

.btn-bluebaby:hover {
  background: #46bdd7;
  color: #fff;
}

.btn-bluebaby:active {
  border-color: #46bdd7;
  background: #36a2bb !important;
}

.btn-mult-content {
  padding: 0.35rem 1rem;
  display: inline-block;
  border-radius: 0.375rem;
}

.btn-mult-content:hover {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-mult-content:active {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  background: #e8e8e8 !important;
  box-shadow: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-square {
  width: 50px;
  height: 50px;
  padding: 0 !important;
}

.btn-link {
  color: #6b9737;
  padding-left: 0;
  padding-right: 0;
}

.btn-link:hover {
  color: #48682a;
}

.dropdown-menu:not(.datepicker-dropdown) {
  position: absolute;
  padding-top: 0;
  padding-bottom: 0;
  right: 0;
  left: auto;
  border-radius: 0.4375rem;
}

.dropdown-menu:not(.dropdown-menu-right):not(.datepicker-dropdown) {
  min-width: 420px;
}

.dropdown-menu.show {
  animation: show-navbar-dropdown 0.25s ease forwards;
  pointer-events: auto;
  opacity: 1;
}

.dropdown-menu.close {
  display: block;
  animation: hide-navbar-dropdown 0.15s ease backwards;
}

.dropdown-menu h4 {
  font-size: 0.98rem !important;
}

.open-dropdown.checked {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  background: #e8e8e8 !important;
  box-shadow: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.dropdown-menu .list-group-item .col-auto {
  padding: 0 10px;
}

.avatar {
  border-radius: 0.375rem;
  width: 60px;
  height: 60px;
}

.dropdown-menu:not(.datepicker-dropdown):before {
  position: absolute;
  z-index: -5;
  bottom: 100%;
  right: 30px;
  left: auto;
  display: block;
  width: 18px;
  height: 18px;
  content: "";
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .box-cart {
    position: static;
  }

  .dropdown-menu {
    position: absolute;
    right: auto;
    left: 3%;
    width: 94%;
    min-width: auto;
  }

  .dropdown-menu:before {
    display: none;
  }
}

@keyframes show-navbar-dropdown {
  0% {
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    transform: translate(0, 10px);
    opacity: 0;
  }
}

.icon-tooltip {
  font-family: "Arial", sans-serif;
  border: solid 1px #36a2bb;
  border-radius: 10px;
  color: #36a2bb;
  cursor: pointer;
  display: inline-block;
  font-size: 9px;
  line-height: 9px;
  margin-left: 8px;
  padding: 3px 6px;
  position: relative;
  -webkit-transform: translateY(-3.5px);
  -moz-transform: translateY(-3.5px);
  transform: translateY(-3.5px);
  z-index: 2;
}

.modal-search .modal-dialog {
  max-width: 800px;
  align-items: flex-start;
}

.modal-search .modal-footer {
  justify-content: center;
}

.modal-search.fade .modal-dialog {
  transition: transform 0.15s ease-out;
}

.modal-search .modal-body {
  padding: 0 1.5rem !important;
}

.modal-footer {
  background: #f7fafc !important;
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}

@media (min-width: 576px) {
  .modal-espiar .modal-dialog {
    width: 1000px;
    max-width: 90%;
  }
}

.slider-img {
  position: relative;
  width: 80% !important;
  float: left;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 416px;
}

.slider-img .badge {
  position: absolute;
  bottom: 0;
  left: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slider-img-group {
  width: 20% !important;
  float: left;
  margin: 0 !important;
  width: 90px;
  max-height: 416px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 15px;
}

.item-group-galeria {
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  margin-bottom: 14px;
  height: 90px;
}

.it-min-sel {
  opacity: 0.4;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.img-galeria-d {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.item-galeria-d {
  position: relative;
  min-width: 100%;
  height: 100%;
}

.img-galeria-gr-d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.prod-page .slider-img-group {
  max-height: 450px;
}

.prod-page .slider-img {
  height: 450px;
}

.btn-absolute {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-estatico {
  position: relative;
  width: 100%;
  height: 360px;
  margin-top: 30px;
  padding: 90px 0;
  border: 1px solid #e8e8e8;
  text-align: center;
}

.list-boxes {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
  width: 100%;
}

.wrapper-prod {
  padding: 10px;
  width: 285px;
}

.box-prod {
  position: relative;
  height: 390px;
  background: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
}

.box-prod span {
  background: #f4f4f4;
  color: black;
  font-size: .75rem;
  padding: 5px 20px;
  display: block;
  width: 100%;
}

.box-prod .nome-prod {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin-bottom: 0 !important;
  bottom: 0;
  left: 0;
  color: white !important;
  text-transform: uppercase;
  font-family: 'akrobatblack', sans-serif !important;
  padding: 10px 15px;
}

.box-prod:hover {
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.img-prod {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-prod:after {
  position: absolute;
  height: 100%;
  border-radius: 4px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.1) 50%, #000 100%);
  content: '';
  opacity: .7;
  transition: opacity .5s;
}

.img-prod img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.nome-prod {
  font-weight: bold;
  margin-bottom: 0;
  color: black;
  text-align: center;
}

.desc-prod {
  font-size: 13px;
  text-align: center;
  color: black;
}

.container-promo {
  z-index: 2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 20px;
}

.preco-prod-box {
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  text-align: center;
}

.preco-prod {
  font-size: 22px;
  color: #6b9737;
}

.preco-prod-old {
  color: #b4b2b2 !important;
  text-decoration: line-through;
}

.box-btns-prod {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
}

.btn-prod {
  position: relative;
  cursor: pointer;
  text-align: center;
  float: left;
  width: 50%;
  color: #858585;
  font-size: 14px;
  padding: 10px 5px;
  box-sizing: border-box;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.btn-prod .fa {
  font-size: 18px;
}

.btn-prod:hover {
  color: black;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.content-btn-prod {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
}

.zoomContainer {
  z-index: 99999999999 !important;
}

.page-link {
  background: transparent;
  color: gray !important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  border-color: #5e72e4 !important;
  background-color: #5e72e4 !important;
}

.card-columns {
  margin-top: 25px;
}

.card-columns .card,
.card-conta,
.card-deck .card {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: none;
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
}

.card-columns .card:hover,
.card-conta:hover,
.card-deck .card:hover {
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.m-timestamps svg {
  display: block;
  position: absolute;
  top: 2px;
  fill: #c5c5c5;
  display: inline-block;
  margin-right: 11px;
  width: 14px;
  height: 14px;
}

.m-timestamps {
  width: 100%;
  margin: 20px 0;
  padding: 0 0 15px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.75);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  float: right;
  padding-left: 0;
}

.m-timestamps time {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 18px;
  color: #c5c5c5;
  font-family: "Gotham-bold", sans-serif;
  font-size: 0.858rem;
  font-weight: 400;
  padding-left: 25px;
  text-align: left;
  display: block;
}

.figure-img {
  max-width: 100%;
}

.art-body {
  line-height: 24px;
  font-family: "Gotham-book", sans-serif;
  font-size: 18px;
  color: #999;
  width: 100%;
}

.art-body p,
.card-text {
  font-weight: 100;
  font-size: 13px;
  color: #4e4e4e;
}

.art-body img {
  max-width: 100%;
  display: inline-block;
  padding: 25px 0;
}

.card-title {
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.ctn-artigo {
  margin: 0 auto !important;
}

.title-art {
  font-size: 3rem;
  text-align: left;
  color: black;
  line-height: 50px;
}

.card-deck {
  margin-top: 35px;
}

.sec-coments {
  width: 100%;
  margin-top: 35px;
}

.m-comments .fb-comments,
.m-comments .fb-comments * {
  width: 100% !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6b9737 !important;
  background-color: #6b9737 !important;
}

.form-control {
  font-family: "Gotham-book", sans-serif;
}

.o-closed {
  display: none;
}

.input-group-text {
  padding: 0.625rem 1.1rem;
}

.input-erro {
  position: relative;
  border: 1px solid #f75676 !important;
}

.input-erro:before {
  position: absolute;
  display: block;
  padding-left: 35px !important;
  content: "campo incorreto";
  right: 25px;
  border-bottom-left-radius: 2px;
  font-family: "Gotham-book", sans-serif !important;
  color: #f75676;
  font-size: 15px;
  background: url(./../../public/img/_layout/exclamation-sign.svg) white 15px center no-repeat;
  background-size: 14px;
  padding: 0 15px;
  height: 20px;
  top: -12px;
}

.input-erro-bottom .input-group {
  border: 1px solid #fb6340 !important;
}

.input-erro-bottom .invalid-feedback {
  display: block;
}

.invalid-feedback {
  margin-top: 0.45rem;
  padding: 0 0.5rem;
}

.input-disabled {
  background: #eceff1 !important;
}

input:disabled {
  cursor: not-allowed;
}

.alert {
  padding: 0.4rem 0.9rem;
}

.alert-dismissible {
  padding-right: 2.5rem;
}

.alert-dismissible .close {
  right: 0.9rem;
  margin-top: 2px;
}

.alert-icon {
  margin-right: 0.9rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6b9737;
  background-color: #6b9737;
}

.divider-right {
  border-right: 1px solid #e9ecef;
}

.divider-left {
  border-left: 1px solid #e9ecef;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #fc9733;
}

.nav-pills .nav-item {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}

.nav-pills .nav-link {
  color: #8898aa !important;
  font-weight: bold;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.tab-tipo.closed {
  display: none;
}

.input-disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.6 !important;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  opacity: 0.6 !important;
}

.status-form-container .alert {
  width: 100%;
}

.loading-form-container {
  flex-grow: 1;
  justify-content: flex-start !important;
}

.loading-box img {
  margin-right: 8px;
}

.ctn-carrinho {
  padding-top: 50px;
  padding-bottom: 70px;
}

.cv-carrinho {
  margin-top: 30px;
  width: 100%;
  display: inline-block;
}

.cv-wrapp-itens {
  width: 75%;
  float: left;
  border-radius: 2px;
  box-sizing: border-box;
  padding-right: 6px;
}

.cv-wrapp-finalizar {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding-left: 25px;
}

.cv-box-img {
  width: 15%;
  float: left;
  text-align: center;
}

.cv-box-img img {
  max-width: 100%;
  max-height: 100%;
}

.cv-box-infs {
  width: 45%;
  float: left;
  padding-left: 20px;
  font-family: "Gotham-book", sans-serif;
}

.cv-box-options {
  display: inline-block;
  width: 40%;
  float: left;
  padding-left: 20px;
}

.cv-text-default {
  font-family: "Gotham-book", sans-serif;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  color: #818181;
}

.cv-text-menor {
  color: rgba(0, 0, 0, 0.3);
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 13px;
}

.cv-text-nome {
  color: #777;
  font-weight: bold;
  margin-bottom: 6px;
  font-family: "Gotham-book", sans-serif;
  font-size: 20px;
}

.cv-text-price-unit,
.cv-text-price-subtotal {
  margin-top: 0;
  margin-bottom: 6px;
  color: #818181;
  font-family: "Gotham-book", sans-serif;
  font-size: 18px;
}

.cv-wrapp-item {
  display: inline-block;
  margin-top: -6px;
  width: 100%;
  padding: 20px;
  background: #fcfcfc;
  border: 1px solid #f4f4f4;
}

.form-qtd {
  width: 80px !important;
  height: 40px !important;
  padding-right: 20px;
}

.qtd {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 10px !important;
  outline: none;
  color: #aaa;
  height: 50px;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.qtd:focus {
  border-color: #eb4981;
  transition: 0.2s;
}

.texto-preco-promo {
  text-decoration: line-through;
}

.numberfy-container {
  position: relative;
}

.numberfy-container .btnUp,
.numberfy-container .btnDown {
  background-size: auto 6px !important;
  text-align: center;
  position: absolute !important;
  width: 26px;
  right: 2px;
  height: 20px;
  display: block;
}

.numberfy-container .btnUp:hover,
.numberfy-container .btnDown:hover {
  background-color: rgba(235, 73, 130, 0.781) !important;
  cursor: pointer;
}

.numberfy-container .btnUp {
  background: #eb4981 url("./../../public/img/_layout/arrowNumberUp.png") 6px center no-repeat;
  border-top-right-radius: 20px;
  top: 0;
}

.numberfy-container .btnDown {
  background: #eb4981 url("./../../public/img/_layout/arrowNumberDown.png") 6px center no-repeat;
  border-bottom-right-radius: 20px;
  bottom: 0;
}

.cv-box-qtd {
  float: left;
}

.cv-btn-remover {
  float: right;
}

.cv-btn-remover {
  position: relative;
  display: block;
  line-height: 40px;
  margin-left: 40px;
  color: white;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 135px;
  height: 40px;
  border-radius: 20px;
}

.cv-btn-finalizar {
  position: relative;
  display: block;
  line-height: 40px;
  color: white;
  margin-top: 15px;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #5cb85c;
}

.cv-btn-finalizar:hover {
  background-color: #478d47;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cv-btn-finalizar p {
  position: relative;
  margin: 0;
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 15px;
  height: 100%;
  color: white;
  text-align: center;
}

.cv-btn-continuar {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 40px;
  color: white;
  margin-top: 10px;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #38abe0;
}

.cv-btn-continuar:hover {
  background-color: #2a8ebd;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cv-btn-continuar p {
  position: relative;
  margin: 0;
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 15px;
  height: 100%;
  color: white;
  text-align: center;
}

.cv-text-subtotal {
  font-family: "Gotham-book", sans-serif;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
  color: #46bdd7;
  font-size: 26px;
}

.cv-fretes {
  margin-top: 20px;
}

.cv-wrapp-frete {
  display: none;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  background: #fcfcfc;
  margin-top: -5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.cv-wrapp-frete:hover {
  background: #f2f2f2;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.cv-frete-cidade {
  float: left;
  min-height: 100%;
  width: 65%;
  padding: 8px;
  font-family: roboto_slabbold, sans-serif;
  border-right: 1px solid #f4f4f4;
  color: #3f1000;
}

.cv-valor-cidade {
  float: left;
  min-height: 100%;
  width: 35%;
  padding: 8px;
  color: #818181;
  text-align: right;
  font-family: roboto_slabbold, sans-serif;
  font-size: 13px;
}

.cv-text-frete {
  display: none;
  margin-bottom: 10px;
}

.empty-cart {
  width: 100%;
  border-radius: 2px;
  border: 3px dashed rgba(236, 48, 56, 0.3);
  padding: 7px 10px;
  background: rgba(236, 48, 56, 0.25);
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: white;
}

.wrapper-box-h:last-child {
  padding-left: 10px !important;
}

.box-filters-mobile {
  max-width: 100% !important;
  border-radius: 0 !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100%;
}

.alert-text a {
  color: white;
  opacity: 0.65;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.alert-text a:hover {
  opacity: 0.4;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.btn-add-cart .loading-box {
  position: absolute;
  top: 50%;
  margin-top: 0 !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 100%;
  padding-left: 15px;
}

.empty-cart-text {
  width: 100%;
  font-family: "Gotham-book", sans-serif;
  text-align: center;
  font-size: 20px;
  color: #48682a;
  margin-top: 15px;
  font-weight: bold;
  padding: 10px 25px;
}

.empty-cart-img {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.box-empty-cart-pg .empty-cart-img {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.box-empty-cart-pg,
.box-empty-cart {
  opacity: 0.25;
  margin-top: 35px;
}

.alert-text {
  display: inline !important;
  vertical-align: middle;
}

.custom-toggle-white input:checked + .custom-toggle-slider {
  border-color: #fff;
}

.custom-toggle-white input:checked + .custom-toggle-slider:before {
  background: #fff;
}

.custom-toggle-white input:checked + .custom-toggle-slider:after {
  color: #fff;
}

.custom-toggle-slider:after {
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  min-width: 1.66667rem;
  margin: 0 0.21667rem;
  content: attr(data-label-off);
  transition: all 0.15s ease;
  text-align: center;
  color: #ced4da;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
  right: auto;
  left: 1px;
  content: attr(data-label-on);
  color: white;
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 1.5rem;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(2.01rem);
}

.form-secondary .focused .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.form-secondary .input-group-alternative {
  border-radius: 0.375rem !important;
}

.form-secondary .input-group-alternative .form-control {
  padding: 0.625rem 0.75rem !important;
}

.form-secondary .focused .input-group-text {
  color: #fff !important;
  border-right: 1px solid rgba(72, 104, 42, 0.95) !important;
  background-color: rgba(72, 104, 42, 0.6) !important;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.form-secondary .input-group-alternative .form-control {
  color: #fff !important;
  background-color: rgba(72, 104, 42, 0.45);
  border: 1px solid rgba(72, 104, 42, 0.45) !important;
}

.form-secondary .input-group-alternative .form-control:focus {
  color: #fff !important;
  border: 1px solid rgba(72, 104, 42, 0.95) !important;
  background-color: rgba(72, 104, 42, 0.6);
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.form-secondary .input-group-alternative .form-control::-webkit-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control:-moz-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control::-moz-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control:-ms-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-text {
  color: #fff;
  border-right: 1px solid rgba(72, 104, 42, 0.45) !important;
  background-color: rgba(72, 104, 42, 0.45) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, rgba(48, 203, 238, 0.75) 0, rgba(109, 172, 34, 0.7) 100%) !important;
}

.modal[role="dialog"] {
  background: #000000bf;
}

.modal-dialog {
  padding: 15px 15px 35px 15px;
}

.blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  filter: blur(10px);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.border-end-main {
  border: 1px solid #ffd600;
  background: #fff;
  position: relative;
  padding-bottom: 40px !important;
}

.border-end-main:after {
  position: absolute;
  content: "endereço principal (fornecido no cadastro)";
  font-weigth: bold;
  color: white;
  width: 100%;
  background: #ffd600;
  font-size: 11px;
  padding: 5px 10px;
  display: block;
  right: 0;
  bottom: 0;
}

.border-end-default {
  border: 1px solid #2bffc6;
  position: relative;
  padding-bottom: 40px !important;
}

.border-end-default:after {
  position: absolute;
  content: "endereço escolhido como padrão para as próximas compras";
  font-weigth: bold;
  color: white;
  width: 100%;
  background: #2bffc6;
  font-size: 11px;
  padding: 5px 10px;
  display: block;
  right: 0;
  bottom: 0;
}

.checkout-header {
  background: #edf0f3 !important;
  padding: 30px 0 0 0;
}

.checkout-title {
  font-size: 20px;
  color: #bbbdc0 !important;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  font-family: "Gotham-bold", sans-serif;
}

.wrapper-checkout-header {
  display: block;
}

.btn-status-checkout {
  z-index: 1;
  position: relative;
  padding: 2.5px 8px 7px 17px !important;
  border-radius: 24.5px;
  border-color: transparent !important;
  letter-spacing: 0.0625rem;
  font-family: "Gotham-bold", sans-serif;
  background: #cacccf !important;
  font-size: 13px !important;
  box-shadow: none !important;
}

.btn-status-checkout:active {
  background: #cacccf !important;
  border-color: transparent !important;
}

.btn-status-checkout:hover {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}

.btn-status-checkout.active {
  z-index: 2;
  background: #48682a !important;
  color: white !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;
}

.btn-status-checkout.concluido {
  z-index: 3;
  background: #2dce89 !important;
  color: white !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;
}

.btn-status-checkout .btn-inner--text {
  margin-left: 5px !important;
}

.btn-status-checkout .btn-inner--icon {
  font-size: 20px !important;
}

.btn-status-checkout.line-lr {
  margin-left: 20px;
  margin-right: 20px;
}

.btn-status-checkout.line-l {
  margin-left: 20px;
}

.btn-status-checkout.line-r {
  margin-right: 20px;
}

.btn-status-checkout .line-right {
  z-index: 1;
  width: 25px;
  height: 7px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  left: 100%;
  display: block;
  background: #cacccf;
}

.btn-status-checkout .line-left {
  z-index: 1;
  width: 25px;
  height: 7px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  right: 100%;
  display: block;
  background: #cacccf;
}

.btn-status-checkout .line-right:before,
.btn-status-checkout .line-left:before {
  position: absolute;
  content: " ";
  display: block;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background: #cacccf;
}

.btn-status-checkout.active .line-right:before,
.btn-status-checkout.active .line-left:before {
  width: 100%;
  background: #48682a;
}

.btn-status-checkout.concluido .line-right:before,
.btn-status-checkout.concluido .line-left:before {
  width: 100%;
  background: #2dce89;
}

.concluido-sn {
  display: none;
}

.btn-status-checkout.concluido .concluido-sn {
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: block;
  border-radius: 50%;
  position: absolute;
  width: 20px;
  font-size: 10px;
  height: 20px;
  color: white;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  right: -20px;
  background: #2dce89;
}

.btn-status-checkout.concluido .concluido-sn i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pg-processado {
  align-items: center;
  justify-items: center;
  margin-top: 30px !important;
}

.pg-processado img {
  max-width: 200px;
  height: auto;
  margin-left: 10px;
}

.text-conexao {
  color: #2dce89 !important;
}

.pg-compra-segura {
  align-items: center;
  justify-items: center;
}

.pg-compra-segura .text-conexao {
  border-radius: 4px;
  display: inline-block;
  background: #2dce89 !important;
  color: white !important;
}

.link-effect {
  position: relative;
  color: #8898aa !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect:before {
  position: absolute;
  content: "";
  display: block;
  bottom: -8px;
  height: 2px;
  width: 0;
  left: 0;
  background: #8898aa !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect i {
  margin-left: 6px;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.link-effect:hover {
  color: #64707c !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect:hover i {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  transform: translateX(4px);
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.link-effect:hover:before {
  width: 100%;
  background: #64707c !important;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0 !important;
}

.list-item-checkout {
  padding-bottom: 15px !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f7fc;
}

.list-item-checkout:last-child {
  border: none !important;
  padding: 0 !important;
}

.nav-pills .nav-link,
.nav-pills .nav-link {
  font-size: 25px !important;
  line-height: 25px;
}

.nav-pills .nav-link i,
.nav-pills .nav-link i {
  font-size: 50px !important;
  display: block;
  margin-bottom: 5px;
}

.card-flag {
  position: relative;
  display: inline-block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 56px;
  height: 40px;
  vertical-align: middle;
  margin-right: 10px;
}

.text-card-code {
  padding-left: 70px;
  position: relative;
  display: inline-block;
  margin-top: 4px;
  color: #525f7f;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.etapa-red {
  background: #f5365c !important;
}

.etapa-red .concluido-sn {
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: block;
  border-radius: 50%;
  position: absolute;
  width: 20px;
  font-size: 10px;
  height: 20px;
  color: white;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  right: -20px;
  background: #2dce89;
}

.etapa-red .concluido-sn i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.etapa-red .concluido-sn {
  background: #f5365c !important;
}

.copy-text {
  cursor: pointer;
}

.favorito {
  color: #f5365c !important;
}

.bar-filtros {
  background: #f4f4f4 !important;
  width: 100%;
  border-radius: 6px;
}

.bar-filtros .open-sideright {
  display: none;
}

.breadcrumb-item i {
  font-size: 16px;
  margin-right: 5px;
}

.breadcrumb-item {
  font-size: 13px;
  font-family: "Gotham-bold", sans-serif;
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  content: "-";
  color: #8898aa;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
}

.breadcrumb a {
  color: #f4f5f7 !important;
}

.breadcrumb a:hover {
  color: rgba(244, 245, 247, 0.5) !important;
}

.breadcrumb.bc-primary a {
  color: #5e72e4 !important;
}

.breadcrumb.bc-primary a:hover {
  color: rgba(94, 114, 228, 0.5) !important;
}

a {
  text-decoration: none;
  color: #46bdd7;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a:hover {
  color: #36a2bb;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a.dropdown-item:active {
  background: #36a2bb;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a.dropdown-item:first-child {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

a.dropdown-item:last-child {
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}

.pagination {
  justify-content: center;
}

.dropdown-divider {
  border-top: 1px solid #edf2f7;
}

.bc-item {
  font-family: "Gotham-book", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #989898;
  padding: 0 7px 0 5px;
  display: inline-block;
}

.bc-item-m {
  font-family: "Gotham-book", sans-serif;
  font-size: 12px;
  position: relative;
  text-decoration: none !important;
  margin-left: 0;
  font-weight: bold;
  background: transparent;
  border-radius: 10px;
  color: #989898 !important;
  padding: 0 28px 0 0;
  display: inline-block;
}

.remove-bc-attr {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #dadada;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.bc-item-m .remove-bc-attr:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -1px;
  transform: translate(-50%, -50%);
  font-family: "Font Awesome 5 Free";
  top: 5.5px;
  left: 9px;
  display: block;
  content: "\f00d";
  width: 10px;
  height: 10px;
}

.bc-item-m .remove-bc-attr:hover {
  background: #bd3b3e;
}

.bc-item-m .remove-bc-attr:hover:after {
  color: white;
}

.bc-item-attr {
  display: inline-block;
}

@media (max-width: 760px) {
  .card-columns {
    column-count: 2 !important;
  }
}

@media (max-width: 578px) {
  .card-columns {
    column-count: 1 !important;
  }

  .card-columns .card {
    width: 100%;
    float: left;
  }
}

@media (max-width: 480px) {
  .card-columns .card {
    width: 100%;
    float: left;
  }
}

.ctn-top-head-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 14px rgba(0, 0, 0, 0.88) !important;
  z-index: 13;
  background-color: white !important;
  height: 80px;
}

@media (max-width: 992px) {
  .title-tip-1 {
    font-size: 40px !important;
  }

  .ctn-top-head-mobile {
    display: block;
  }

  .ctn-header {
    display: none !important;
  }

  .ctn-top-header {
    display: none !important;
  }

  .list-boxes {
    justify-content: center !important;
  }

  body {
    margin-top: 80px !important;
  }

  .container {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

.ctn-float-btn {
  display: block;
  position: fixed;
  bottom: -100px;
  z-index: 11;
  right: 30px;
  width: 45px;
  height: 45px;
  transition: 0.3s;
}

.float-btn-effect {
  bottom: 45px;
  transition: 0.35s;
}

.ctn-float-btn {
  display: block;
  position: fixed;
  bottom: -100px;
  z-index: 155;
  right: 30px;
  width: 45px;
  height: 45px;
  transition: 0.3s;
}

.ctn-float-btn-file-mob,
.ctn-float-btn-file {
  display: block;
  text-decoration: none !important;
  position: fixed;
  bottom: 50px;
  z-index: 11;
  left: 30px;
  width: auto;
  transition: 0.3s;
}

.ctn-float-btn-file .float-btn,
.ctn-float-btn-file-mob .float-btn {
  font-family: "chau_philomene_oneregular", sans-serif;
  line-height: 38px;
  height: 38px;
  border-radius: 18.5px !important;
  padding: 0 20px;
  background: #46bdd7;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
}

.float-btn-effect {
  bottom: 45px;
  transition: 0.35s;
}

.float-btn {
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  position: relative;
  background: white;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  z-index: 11;
  color: #909090;
  font-size: 20px;
}

.float-btn:hover {
  transition: 0.3s;
  background: #fc9733;
}

.ctn-float-btn-file .float-btn:hover {
  transition: 0.3s;
  background: #38abe0;
  color: white !important;
}

.wrapper-lines-up {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
}

.wrapper-lines-up span {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: 3px;
  transition: 0.15s;
  background-color: #fc9733;
  border-radius: 2px;
}

.wrapper-lines-up span:nth-of-type(1) {
  -webkit-transform: translate3d(-4px, 4px, 0) rotate(-45deg) scaleX(0.6);
  transform: translate3d(-4px, 4px, 0) rotate(-45deg) scaleX(0.6);
}

.wrapper-lines-up span:nth-of-type(2) {
  -webkit-transform: translate3d(4px, 4px, 0) rotate(45deg) scaleX(0.6);
  transform: translate3d(4px, 4px, 0) rotate(45deg) scaleX(0.6);
}

.wrapper-lines-up span:nth-of-type(3) {
  width: 3px;
  height: 18px;
  left: 50%;
  -webkit-transform: translate3d(-50%, 3px, 0) rotate(0deg);
  transform: translate3d(-50%, 3px, 0) rotate(0deg);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span {
  background: white;
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(3) {
  height: 26px;
  -webkit-transform: translate3d(-50%, 0px, 0) rotate(0deg);
  transform: translate3d(-50%, 0px, 0) rotate(0deg);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(1) {
  -webkit-transform: translate3d(-4px, 0px, 0) rotate(-45deg) scaleX(0.6);
  transform: translate3d(-4px, 0px, 0) rotate(-45deg) scaleX(0.6);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(2) {
  -webkit-transform: translate3d(4px, 0px, 0) rotate(45deg) scaleX(0.6);
  transform: translate3d(4px, 0px, 0) rotate(45deg) scaleX(0.6);
  transition: 0.15s;
}

.box-tipo-mob:first-child {
  border-right: 1px solid #6b9737;
}

.box-tipo-mob {
  text-align: center;
  padding: 10px 15px;
  background: #74a63d;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-tipo-mob:hover,
.box-tipo-mob:active {
  background: #6b9737 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-tipo-mob.selected {
  background: #46bdd7 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-menu,
.box-search {
  width: auto;
  height: 100%;
}

.btn-search-mob {
  position: relative;
  margin: 0 !important;
}

.box-logo-mob {
  width: 100%;
  height: 100%;
  background: url("./../../public/img/_layout/logo.png") center center no-repeat;
  background-size: contain;
  justify-content: center;
}

@media (max-width: 520px) {
  .box-logo-mob {
    background: url("./../../public/img/_layout/logo.png") center center no-repeat;
    background-size: contain;
  }
}

.hamb-wrapper {
  cursor: pointer;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
  height: 42px;
  float: right;
}

.hamb-container {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0 10px 0 0;
  display: block;
  margin: auto;
  width: 42px;
  float: left;
  border-radius: 4px;
}

.hamb-line {
  position: relative;
  width: 100%;
  display: block;
  height: 4px;
  border-radius: 3px;
  margin: 0 0 7px 0;
  transition: transform 0.3s;
  background: #5e72e4;
}

.hamb-line:nth-child(2) {
  width: 60%;
}

.hamb-line:last-child {
  margin-bottom: 0;
}

.hamb-wrapper p {
  position: relative;
  float: left;
  margin-left: -20px;
  display: inline-block;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: #5e72e4;
  line-height: 16px;
}

.wrapper-gal-prod {
  background: white;
}

.popup {
  padding-left: 0 !important;
}

.modal-xl img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: 800px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}

@media (max-width: 1200px) {
  .wrapp-noticia {
    width: 33.33333% !important;
  }

  .sob-title {
    margin-top: 1rem !important;
  }
}

@media (max-width: 1000px) {
  .col-foot-news-title,
  .col-foot-news {
    width: 100%;
    padding: 0;
  }

  .col-foot-news {
    margin-top: 20px;
  }

  form[name="newsletter"] .col-md-5 {
    width: 100% !important;
    flex: 1;
  }

  form[name="newsletter"] .col-md-5:nth-child(2) {
    padding-right: 0 !important;
  }

  .menu-footer {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .col-prod-esp-inf {
    max-height: 416px;
    overflow-y: scroll;
  }
}

@media (max-width: 992px) {
  .wrapper-prod {
    width: 325px;
  }

  .sob-home {
    padding-bottom: 50px !important;
  }

  .wrapp-noticia {
    width: 50% !important;
  }

  .col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;
    float: none !important;
  }

  .col-100 h1 {
    margin-top: 10px !important;
  }

  .prod-page .col-8,
  .prod-page .col-4,
  .modal-espiar .modal-body .col-8,
  .modal-espiar .modal-body .col-4 {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    padding: 0 !important;
  }

  .modal-espiar .modal-body .col-4,
  .prod-page .col-4 {
    margin-top: 25px;
  }

  .slider-img .badge {
    display: none !important;
  }

  .bar-filtros {
    padding: 15px 20px;
    background: transparent !important;
  }

  .bar-filtros nav {
    display: none !important;
  }

  .bar-filtros .open-sideright {
    display: block;
    margin: 0 auto;
    width: 350px;
    max-width: 100%;
  }

  .row-pag {
    display: block !important;
    width: 100% !important;
  }

  .title-art {
    font-size: 3rem !important;
    line-height: 50px !important;
  }

  .share-btns-arts {
    width: 100%;
  }

  .share-btns-arts > .row {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .share-btns-arts button {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .share-btns-arts h6 {
    text-align: center;
  }

  .row-m-conta .col-6:first-child {
    margin-bottom: 20px;
  }

  .row-m-conta .col-6 {
    width: 100%;
    flex: none !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .row-end {
    width: 100%;
    display: block !important;
  }

  .row-end > .col-8,
  .row-end > .col-4 {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    padding: 0 !important;
  }

  .row-end > .col-8 {
    margin-top: 30px;
  }

  .checkout-title {
    width: 100%;
  }

  .checkout-header {
    padding: 0;
  }

  .wrapper-checkout-header nav {
    width: 100%;
    float: none;
  }

  .btn-status-checkout {
    width: 100%;
    float: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .btn-status-checkout .line-right {
    width: 7px;
    height: 12px;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .btn-status-checkout .line-left {
    display: none !important;
  }

  .line-r,
  .line-lr,
  .line-l {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .cv-wrapp-itens,
  .cv-wrapp-finalizar {
    width: 100%;
    padding: 0;
  }

  .cv-wrapp-finalizar {
    margin-top: 20px;
  }

  .cv-box-img {
    width: 45% !important;
  }

  .cv-box-infs {
    width: 55% !important;
  }

  .cv-box-options {
    width: 100%;
    padding: 0 !important;
  }

  .cv-box-img .col-auto {
    height: 120px !important;
  }

  .col-end-dados .row,
  .box-end-sel .row {
    flex-flow: wrap !important;
  }

  .col-end-dados .col-5,
  .col-end-dados .card-title {
    max-width: 100% !important;
    flex: none !important;
    width: 100% !important;
  }

  .row-pag .col-md-8,
  .row-pag .col-md-4 {
    width: 100%;
    max-width: 100%;
    flex: none;
    padding: 0;
  }

  .row-pag .col-md-4 {
    margin-top: 30px;
  }
}

@media (max-width: 1120px) {
  .col-text-sobre {
    width: 100% !important;
    border: 0 !important;
    padding: 0 !important;
    margin-bottom: 35px;
  }

  .col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;
    float: none !important;
  }
}

@media (max-width: 770px) {
  .menu-footer {
    transform: translateX(20px);
  }

  .wrapper-prod {
    width: 375px;
    max-width: 100% !important;
  }

  .prods-rel {
    display: block !important;
    width: 100% !important;
  }

  .wrapp-noticia {
    width: 100% !important;
  }

  .modal-footer {
    flex-direction: column !important;
    align-items: unset;
  }

  .modal-footer button {
    margin-left: 0;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .modal-espiar .modal-footer .btn-add-cart {
    margin: 0 !important;
    width: 100% !important;
  }

  .modal-espiar .modal-footer .btn-link {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .title-art {
    font-size: 2.7rem !important;
    line-height: 42px !important;
  }

  .row-identificacao .col-md-6 {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none;
  }

  .divider-right {
    border-bottom: 1px solid #e9ecef !important;
    padding-bottom: 30px !important;
  }

  .frete .row {
    flex-direction: column;
  }

  .frete .row button {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 20px !important;
  }

  .frete .row .col {
    max-width: 100%;
    flex-basis: auto !important;
    flex-grow: 1;
    margin-top: 15px;
    padding: 0 !important;
    text-align: center;
  }

  .pg-processado {
    display: block;
    text-align: center;
  }

  .pg-processado .text-muted {
    margin: 0 !important;
  }

  .pg-processado .text-muted:nth-child(2) {
    display: none !important;
  }

  .pg-processado img {
    margin-left: 0 !important;
    max-width: 80% !important;
  }

  .row-end-chk button {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }

  .form-area-to-send .col-md-6 {
    width: 100% !important;
    padding: 0;
    max-width: 100%;
    flex: none;
  }
}

@media (max-width: 480px) {
  .newsletter-text {
    line-height: 26px;
  }

  form[name="newsletter"] .col-md-5 {
    width: 100% !important;
    padding: 0 !important;
    flex: none !important;
  }

  .menu-footer {
    transform: none !important;
  }

  .menu-footer ul {
    width: 100% !important;
    flex: none !important;
    max-width: 100% !important;
    padding: 0;
  }

  h1 {
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .title-art {
    font-size: 2.5rem !important;
    line-height: 40px !important;
  }

  .row-prod-title {
    padding: 0 !important;
  }

  .row-prod-title p {
    width: 100% !important;
  }

  .row-prod-title .btn-absolute {
    position: relative;
    margin-top: 5px !important;
    left: 0;
    top: 0;
  }
}

@media (max-width: 460px) {
  .text-copy {
    font-size: 12px !important;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  .ctn-fot_rights {
    padding: 15px 0 !important;
    height: auto !important;
  }

  .item-group-galeria {
    height: 65px !important;
  }

  .slider-img {
    height: 316px !important;
  }

  .row-m-conta .card-title {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .row-m-conta .h2 {
    font-size: 0.95rem;
  }

  .row-m-conta .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .row-m-conta .icon-shape i {
    font-size: 1rem;
  }

  .cv-box-img .col-auto {
    height: 150px !important;
  }
}

@media (max-width: 395px) {
  .modal-espiar .modal-footer .btn-link {
    font-size: 10px !important;
  }

  .item-group-galeria {
    height: 48px !important;
  }

  .slider-img {
    height: 256px !important;
  }

  .title-art {
    font-size: 1.6rem !important;
    line-height: 28px !important;
  }

  .row-identificacao .btn-link {
    font-size: 10px !important;
  }

  .row-m-conta .card-title {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .row-m-conta .h2 {
    font-size: 0.75rem;
  }

  .cv-box-img,
  .cv-box-infs {
    margin-bottom: 15px;
    padding: 0 !important;
    width: 100% !important;
  }

  .row-title-pag {
    flex-direction: column;
  }

  .row-title-pag .col-auto {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .row-title-pag .col {
    max-width: 100%;
    flex-basis: auto;
    flex-grow: 1;
  }

  .box-tipo-mob {
    width: 100% !important;
    max-width: 100%;
    flex: none !important;
    border: none !important;
  }
}

.nav-item {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.nav-item .nav-link:hover {
  color: #da6d00 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 20px !important;
}

.input-group-alternative {
  background: transparent;
  border: 1px solid #5e72e4;
}

.input-group-text i {
  color: #5e72e4;
}

form[name="newsletter"] input {
  color: #5e72e4 !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="newsletter"] input::placeholder {
  color: #5e72e4 !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="contato"] input,
form[name="contato"] textarea {
  color: #5e72e4 !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="contato"] input::placeholder,
form[name="contato"] textarea::placeholder {
  color: #5e72e4 !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

.wrpp-its_top {
  position: absolute;
  bottom: 50px;
  left: 200px;
}

.box-it-feira {
  position: relative;
  display: block;
  flex: 1;
  margin: 10px 20px;
  width: 240px;
  height: 315px;
}

.content-it-feira {
  position: relative;
  z-index: 2;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  border: 3px solid #b2b2b2;
  text-align: center;
  padding: 60px;
  background: #f2f2f2;
  width: 100%;
  height: 100%;
}

.content-it-feira img {
  margin-bottom: 45px;
}

.content-it-feira img.w {
  display: none;
}

.content-it-feira p {
  font-family: "oxygenbold", sans-serif;
  color: #0f0f0f;
  font-size: 17px;
  margin-bottom: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-back-feira {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 70%;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 6px solid #fe8100;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.box-it-feira:hover img {
  display: none;
}

.box-it-feira:hover img.w {
  display: inline-block;
}

.box-it-feira:hover .content-it-feira {
  background: #fe8100;
  border-color: #fe8100;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-it-feira:hover p {
  color: white;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-it-feira:hover .box-back-feira {
  bottom: -10px;
  width: 90%;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

button {
  font-size: 16px !important;
  padding: 0.35rem 1.85rem !important;
}

.box-it-show {
  margin: 15px;
  cursor: pointer;
}

.box-it-show h3 {
  text-align: center;
  font-family: "bebas_neueregular", sans-serif;
  letter-spacing: 1px;
  font-size: 32px;
  color: white;
}

.content-it-show {
  border-radius: 12px;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  overflow: hidden;
  width: 220px;
  height: 288px;
  background: #fe8100;
  margin: auto;
}

.content-it-show:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.img-show {
  width: 100%;
  height: 240px;
  position: relative;
  background: rgba(205, 123, 41, 0.49);
}

.img-show i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  font-size: 58px;
}

.content-it-show p {
  padding: 15px;
  color: white;
  font-size: 11px;
  text-align: justify;
}

.data-show {
  font-size: 20px !important;
  margin-bottom: 0 !important;
  text-align: center !important;
  background: rgba(228, 136, 46, 0.58);
}

.wrapp-noticia {
  width: 25%;
  display: block;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}

.box-noticia {
  text-decoration: none !important;
  display: block;
  position: relative;
  width: 100%;
  height: 428px;
  background: white;
}

.head-not {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.img-not {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: 0.15s;
}

.box-noticia .title-not {
  position: absolute;
  z-index: 3;
  font-size: 18px;
  margin-bottom: 0;
  color: white;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px 15px;
}

.content-not {
  position: relative;
}

.post-infs-not {
  text-align: left;
}

.post-infs-not {
  font-family: "Geomanist", sans-serif;
  padding: 10px 15px;
  margin-bottom: 0;
  font-weight: 300;
  color: #a5a5a5;
  font-size: 12px;
}

.post-infs-not span {
  float: right;
  font-size: 10px !important;
}

.wrapp-noticia .text-not {
  position: absolute;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  left: 0;
  top: 37px;
}

.box-noticia .text-not {
  padding: 0 15px 15px 15px;
}

.head-not:after {
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.95) 100%);
  content: "";
  opacity: 0.95;
  transition: 0.15s;
}

.box-noticia:hover .head-not:after {
  opacity: 1;
  height: 60%;
  transition: 0.3s;
}

.box-noticia:hover .img-not {
  transform: translate(-50%, -50%) scale(1.12);
  -webkit-transform: translate(-50%, -50%) scale(1.12);
  transition: 0.3s;
}

.inTop .nav-link {
  height: 100%;
}

.inTop .box-logo {
  width: 100px;
  text-align: center;
}

.box-logo-patr {
  width: 100%;
  height: 200px;
}

.lk-menu {
  color: #616161 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  padding-top: 0 !important;
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  padding-bottom: 0 !important;
  font-family: "oxygenbold", sans-serif;
  font-size: 15px !important;
  letter-spacing: 3px !important;
  text-transform: uppercase;
}

.lk-menu:not(.lk-menu-active):hover {
  color: #fc9733 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.lk-menu-active {
  color: #fe8100 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-foto:not(.i-bann) {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  width: 257px;
  height: 282px;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.15);
}

.box-foto:hover .overlay-foto {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-foto:hover .foto {
  -webkit-transform: translate(-50%, -50%) scale(1.2);
  -moz-transform: translate(-50%, -50%) scale(1.2);
  transform: translate(-50%, -50%) scale(1.2);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.overlay-foto {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  width: 120px;
  height: 120px;
  position: absolute;
  padding: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.55);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.overlay-foto p i {
  font-size: 32px;
  margin-bottom: 10px;
}

.overlay-foto p {
  margin-bottom: 0;
  font-size: 13px;
  color: white;
}

.overlay-foto div {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.foto {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

#fancybox-thumbs {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 8050;
}

#fancybox-thumbs.bottom {
  bottom: 2px;
}

#fancybox-thumbs.top {
  top: 2px;
}

#fancybox-thumbs ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

#fancybox-thumbs ul li {
  float: left;
  padding: 1px;
  opacity: 0.5;
}

#fancybox-thumbs ul li.active {
  opacity: 0.75;
  padding: 0;
  border: 1px solid #fff;
}

#fancybox-thumbs ul li:hover {
  opacity: 1;
}

#fancybox-thumbs ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #222;
  background: #111;
  outline: none;
}

#fancybox-thumbs ul li img {
  display: block;
  position: relative;
  border: 0;
  padding: 0;
  max-width: none;
}

.artigos-list .wrapp-noticia {
  width: 33.3333333%;
}

.table .thead-light th {
  color: white;
  background-color: #fc9733;
  font-size: 0.95rem;
}

.table td {
  font-size: 0.9rem !important;
}

.nav-wrapper .nav-link {
  font-family: "oxygenlight", sans-serif;
  font-weight: 100;
  text-transform: none !important;
}

.nav-pills .nav-link:hover {
  color: #687584 !important;
  background-color: #eaeaea !important;
}

.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  color: #fff !important;
  background-color: #da6d00 !important;
}

.it-show-pg {
  position: relative;
}

.badge-primary {
  color: #da6d00;
  margin-bottom: 20px;
  font-size: 15px;
}

body {
  font-family: 'akrobatregular', sans-serif !important;
  overflow-x: hidden;
  background: #3D3F92;
}

h1,
p,
span,
h2,
h3 {
  font-family: 'akrobatregular', sans-serif !important;
}

.foot {
  background: #3D3F92;
}

.ctn-fot_rights {
  background: #BD3B43;
  color: white !important;
  padding: 20px 0;
}

.text-copy {
  color: white !important;
}

.text-contato {
  text-align: center;
  font-size: .75rem;
  color: #6B696C;
  font-family: 'akrobatregular', sans-serif;
}

.text-contato a {
  font-family: 'akrobatbold', sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #6B696C;
}

.text-contato span {
  display: block;
  font-size: 30px;
  line-height: 35px;
}

.text-contato span b {
  font-family: 'akrobatblack', sans-serif;
}

.btn-yellow {
  background: #BD3B43;
  color: #fff;
  padding: 0.50rem 2.85rem !important;
}

.btn-yellow:before {
  position: absolute;
  content: ' ';
  display: block;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  background: #ffff39;
  box-shadow: 0 0 14px 10px #ffff39, 0 0 20px 10px #ffff39, 0 0 65px 13px #FFFF3A;
  width: 70%;
  border-radius: 50%;
  height: 50%;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-yellow:before {
    transition: none;
  }
}

.btn-yellow:hover {
  background: #BD3B43;
  color: #fff;
}

.btn-yellow:hover:before {
  box-shadow: 0 0 18px 12px #ffff39, 0 0 20px 10px #ffff39, 0 0 70px 20px #FFFF3A;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-yellow:hover:before {
    transition: none;
  }
}

.btn-yellow:active {
  border-color: #BD3B43;
  background: #7c272f !important;
}

.btn-yellow:active:before {
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-yellow:active:before {
    transition: none;
  }
}

.btn-yellow span {
  position: relative;
  font-family: 'bebas_neueregular', sans-serif;
  color: black;
  font-size: 1.3rem;
}

header section {
  position: relative;
  z-index: 1;
}

.ctn-top-header {
  background-color: #F2F0F1;
  height: 105px;
  border-top: none;
  z-index: 4;
}

.ctn-top-header .header-infs {
  background: black;
  padding: 3px 0;
}

.ctn-top-header .header-infs p {
  display: inline-block;
  margin: 0 !important;
  font-size: 14px;
  color: white;
}

.ctn-top-header .header-infs p:first-child {
  margin-right: 20px !important;
}

.ctn-top-header .navbar-horizontal {
  width: 100%;
  height: calc(100% - 34px);
}

.ctn-top-header .nav-item {
  position: relative;
  padding-right: .8rem !important;
  padding-left: .8rem !important;
}

.ctn-top-header .nav-item.active:before {
  width: 100%;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ctn-top-header .nav-item.active:before {
    transition: none;
  }
}

.ctn-top-header .nav-link {
  position: relative;
  z-index: 2;
  line-height: 76px;
  color: #6B696C !important;
}

.ctn-top-header .nav-link:hover {
  color: #BD3B43 !important;
}

.ctn-top-header .box-logo {
  position: relative;
  width: 165px;
  height: 100%;
  transition: 0.4s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ctn-top-header .box-logo {
    transition: none;
  }
}

.btn-rs {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 20px;
  height: 20px;
  background: white;
  color: black;
  border-radius: 50%;
}

.btn-rs .fab {
  position: absolute;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.btn-rs:hover {
  background: #BD3B43;
  color: white !important;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn-rs:hover {
    transition: none;
  }
}

.ctn-banner {
  background: #3D3F92;
}

.inTop .box-logo {
  width: 215px !important;
  transition: 0.4s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .inTop .box-logo {
    transition: none;
  }
}

.float-btn:hover {
  transition: 0.3s;
  background: #BD3B43;
}

.wrapper-lines-up span {
  background-color: #BD3B43;
}

.cat-home {
  width: 380px;
  padding: 0 40px;
  margin-bottom: 60px;
}

.cat-home div:first-child {
  padding-left: 45px;
}

.cat-home div:nth-child(2) {
  position: relative;
  height: 328px;
}

.cat-home img {
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cat-home p {
  color: white;
  font-family: 'bebas_neueregular', sans-serif;
  text-align: justify;
  font-size: 14px;
}

.cat-home button {
  margin-top: 30px;
}

.title-tip-1 {
  font-family: 'akrobatextrabold', sans-serif !important;
  font-size: 60px !important;
  color: #6B696C;
  text-transform: uppercase;
  position: relative;
}

.title-tip-1:before {
  position: absolute;
  content: ' ';
  display: block;
  width: 200px;
  height: 5px;
  background: #6B696C;
  max-width: 70%;
  left: 50%;
  bottom: 5px;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.title-tip-1.white {
  color: white !important;
}

.title-tip-1.white:before {
  background: white !important;
}

#map {
  height: 475px;
}

.subtitle-tip-2 {
  font-family: 'bebas_neuelight', sans-serif;
  display: inline-block;
  font-size: 34px !important;
  color: white;
}

.sob-home p {
  color: #6B696C;
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  line-height: 25px;
}

.input-disabled {
  background: rgba(236, 239, 241, 0.16) !important;
}

.prod-head {
  margin-bottom: 0;
  border: 6px solid #3D3F92;
}

.wrpp-prod_infs {
  background: #ececec;
  padding: 15px 20px;
  height: 130px;
}

.nome-prod {
  font-family: 'poppinsregular', sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
  color: #3D3F92;
}

.desc-prod {
  font-family: 'akrobatbold', sans-serif;
  font-size: 14px;
  text-align: left;
  color: black;
}

.wrapper-prod {
  width: 255px;
}

a {
  color: #BD3B43;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}

a:hover {
  color: #7c272f;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  a:hover {
    transition: none;
  }
}

.sidemenuright-header {
  font-family: "poppinsregular", Sans-Serif;
  background: #3D3F92 !important;
}

.content-sidemenu .dropdown-item {
  color: #3D3F92 !important;
  font-family: "poppinsregular", Sans-Serif;
}

.breadcrumb {
  margin-bottom: 2.0rem;
}

.page-link {
  background: transparent;
  color: white;
}

.page-link:hover {
  color: #3D3F92;
}

.page-item.active .page-link {
  border-color: #BD3B43;
  background-color: #BD3B43;
  color: white !important;
}

.page-item.disabled .page-link {
  pointer-events: none;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.12);
}

.bar-filtros {
  background: transparent !important;
  width: 100%;
  border-radius: 6px;
}

.title-filter {
  font-family: 'poppinsregular', sans-serif;
  position: relative;
  padding: 3px 30px 3px 0;
  text-align: right;
  color: white;
}

.title-filter:before {
  position: absolute;
  content: ' ';
  display: block;
  right: 0;
  top: 0;
  width: 15px;
  background: #BD3B43;
  height: 100%;
}

.item-filter {
  padding: 4px 0;
  text-align: right;
  color: white;
}

.btn-primary {
  background: #3D3F92;
  border: 2px solid transparent !important;
}

.btn-primary .btn-inner--text {
  font-family: 'poppinslight', sans-serif !important;
  margin-left: .25em !important;
}

.btn-primary:hover {
  background: #3D3F92;
}

.btn-primary:active {
  background: #323264 !important;
  border-color: #3D3F92 !important;
}

.btn-white {
  background: white;
  border: 2px solid transparent !important;
  font-family: 'akrobatbold', sans-serif !important;
  font-weight: bold;
  color: #3D3F92 !important;
  font-size: 1.8rem !important;
}

.btn-white .btn-inner--text {
  font-family: 'akrobatbold', sans-serif !important;
  margin-left: .25em !important;
}

.btn-white:hover {
  background: #fff;
}

.btn-white:active {
  background: #323264 !important;
  border-color: #3D3F92 !important;
  color: white !important;
}

.btn-secondary {
  background: #BD3B43;
  border: 2px solid transparent !important;
  color: white !important;
  font-family: 'akrobatbold' !important;
  font-weight: bold;
  font-size: 25px;
}

.btn-secondary .btn-inner--text {
  margin-left: .25em !important;
}

.btn-secondary:hover {
  background: #BD3B43;
}

.btn-secondary:active {
  background: #7c272f !important;
  border-color: #BD3B43 !important;
}

.btn-dark {
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid transparent !important;
}

.btn-dark .btn-inner--text {
  font-family: 'poppinslight', sans-serif !important;
  margin-left: .25em !important;
}

.btn-dark:hover {
  background: rgba(255, 255, 255, 0.15);
}

.btn-dark:active {
  background: rgba(255, 255, 255, 0.25) !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.title-prod {
  font-family: 'poppinsregular', sans-serif;
  text-transform: uppercase;
  font-size: 1.6rem;
}

.badge-codigo {
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid transparent !important;
  font-family: 'poppinslight', sans-serif;
  font-size: 0.8rem;
  letter-spacing: 2px;
}

.badge-codigo span {
  font-family: 'poppinslight', sans-serif;
  font-size: 0.8rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #BD3B43;
  background-color: #BD3B43;
}

.wrapper-gal-prod {
  background: transparent !important;
}

.slider-img .badge {
  color: white !important;
  background: rgba(255, 255, 255, 0.15);
  padding: 0.65rem 0.675rem;
}

.it-min-sel {
  opacity: 1;
}

.it-min-sel .img-galeria-d {
  opacity: 0.4;
}

.slider-img-group .item-group-galeria {
  background: white !important;
}

.slider-img-group .img-galeria-d {
  max-width: 85% !important;
  max-height: 85% !important;
}

.item-galeria-d {
  background: white !important;
}

.slider-img {
  padding-left: 25px;
}

.nav-albuns .nav-link.active {
  color: #3D3F92 !important;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-albuns .nav-link.active {
    transition: none;
  }
}

.nav-albuns .nav-link {
  line-height: 50px !important;
  font-size: 18px !important;
  font-family: 'akrobatbold' !important;
  color: #8898aa;
}

.nav-albuns .nav-link:hover {
  color: #3D3F92 !important;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nav-albuns .nav-link:hover {
    transition: none;
  }
}

.nanoGalleryContainer {
  background: transparent !important;
}

.nanoGalleryThumbnailContainer {
  border: none !important;
  background: transparent !important;
}

.labelImage {
  display: none;
  background: url("./../../public/img/_layout/lup_lj.png") center 30% rgba(234, 207, 50, 0.8) no-repeat !important;
  height: 96% !important;
}

.labelImageTitle {
  display: none !important;
}

.labelDescription {
  text-align: center !important;
  top: 65% !important;
  position: relative !important;
  font-family: 'poppinsregular', sans-serif !important;
  font-size: 18px !important;
  font-weight: bold;
  text-shadow: none !important;
  text-transform: uppercase;
  color: white !important;
}

.modal-title {
  color: white;
}

.modal-content {
  background: #2f2f2f;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 35px rgba(0, 0, 0, 0.807) !important;
}

.modal-footer {
  border: none !important;
  background: #252525 !important;
}

.modal-footer .btn-link {
  color: white;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal-footer .btn-link {
    transition: none;
  }
}

.modal-footer .btn-link:hover {
  color: rgba(255, 255, 255, 0.4);
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal-footer .btn-link:hover {
    transition: none;
  }
}

.slick-track,
.slick-list {
  outline: none !important;
}

.i-bann {
  position: relative;
  display: block;
  padding: 40px;
}

.i-bann .img-banner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.i-bann .row {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.i-bann .title-banner {
  font-family: 'bebas_neuebold', sans-serif;
  font-size: 7.4rem;
  line-height: 5.5rem;
}

.i-bann .subtitle-banner {
  font-family: 'bebas_neuebold', sans-serif;
  font-size: 3rem;
  line-height: 2.4rem;
  color: white;
}

.i-bann .text-banner {
  color: white;
  font-family: 'bebas_neueregular', sans-serif;
  text-align: justify;
  font-size: 14px;
}

.i-bann .col-auto {
  position: relative;
  padding: 0 !important;
}

.i-bann .col-auto:first-child {
  z-index: 1;
  margin-right: -25px;
}

.i-bann .col-auto:nth-child(2) {
  z-index: 2;
}

.i-bann .col-auto:last-child {
  max-width: 300px;
  z-index: 1;
  margin-left: -25px;
}

.alert-dismissible .close {
  top: 50% !important;
}

.box-servico {
  background: #18235B;
  border: 1px solid #3D3F92;
  padding-top: 30px;
  padding-bottom: 40px;
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-servico {
    transition: none;
  }
}

.box-servico:hover {
  background: #3A437E;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-servico:hover {
    transition: none;
  }
}

.box-servico p {
  color: white;
  font-family: 'akrobatbold';
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0 0 0 !important;
  display: block;
  text-align: center;
}

.box-imgs-sobre {
  position: relative;
  background: transparent !important;
  margin-top: 25px;
  border: 4px solid white;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.3);
}

.box-imgs-sobre .box-imgs-sobre-slider {
  position: static;
  height: 100%;
}

.box-imgs-sobre {
  width: 100%;
  height: 400px;
  background: #aea5a0;
}

.box-marca {
  width: 134px;
  height: 120px !important;
  padding: 15px 30px;
  margin: 15px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.3);
  background-position: center center;
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
}

.marcas-slider .slick-dots {
  bottom: -60px !important;
}

.box-infs {
  padding: 15px 0;
  background: white;
}

.box-info {
  position: relative;
  width: 100%;
  background: #3D3F92;
  padding: 10px;
}

.box-info .wrapper-info {
  width: 100%;
  padding: 15px 10px;
  border: 1px solid white;
  min-height: 245px;
}

.box-info .bottom-info {
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 38px;
}

.box-info .bottom-info .ic {
  position: absolute;
  background: white;
  height: 40px;
  width: 40px;
  bottom: 0;
  left: 50%;
  -moz-transform: rotate(45deg) translateX(-50%);
  -o-transform: rotate(45deg) translateX(-50%);
  -ms-transform: rotate(45deg) translateX(-50%);
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
}

.box-info .bottom-info .ic div {
  width: 100%;
  height: 100%;
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.box-info .bottom-info .ic .ic-contato {
  background: url("./../../public/img/_layout/email_icon.png") center center no-repeat;
}

.box-info .bottom-info .ic .ic-map {
  background: url("./../../public/img/_layout/mapa_site_icon.png") center center no-repeat;
}

.box-info .bottom-info .ic .ic-local {
  background: url("./../../public/img/_layout/local_icon.png") center center no-repeat;
}

.box-info .bottom-info .ic .ic-fones {
  background: url("./../../public/img/_layout/fone_icon.png") center center no-repeat;
}

.box-info .subtitle-tip-2 {
  width: 100%;
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 1.65rem !important;
  font-family: 'akrobatblack' !important;
}

.box-info .btn-rs {
  background: black;
  color: white;
  width: 30px;
  height: 30px;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-info .btn-rs {
    transition: none;
  }
}

.box-info .btn-rs:hover {
  background: white;
  color: black !important;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-info .btn-rs:hover {
    transition: none;
  }
}

.box-info p {
  color: white;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.6rem;
}

.box-info .lk-in {
  display: block;
  color: white;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-info .lk-in {
    transition: none;
  }
}

.box-info .lk-in:hover {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .box-info .lk-in:hover {
    transition: none;
  }
}

.title-bar {
  padding: 35px 0;
}

.title-bar h1 {
  font-family: 'akrobatblack' !important;
  color: #3D3F92 !important;
  text-transform: uppercase;
  text-align: center;
  font-size: 3.4rem;
}

.content-ajax {
  margin: 0 !important;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #3D3F92;
  padding: 50px 0 90px 0;
  display: none;
}

.shine {
  background: #8489ff;
  background-image: linear-gradient(to right, #8489ff 0%, #8489ff 20%, #6060d0 40%, #8489ff 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

photo {
  display: block !important;
  width: 100%;
  height: 400px;
  margin-top: 15px;
}

box {
  height: 104px;
  width: 100px;
  border-radius: 10px;
}

lines {
  height: 15px;
  border-radius: 8px;
  margin-top: 15px;
  width: 100%;
}

h1.shine {
  height: 30px;
  border-radius: 8px;
  margin-top: 15px;
  width: 100%;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.title {
  display: inline-block;
  font-family: 'akrobatblack' !important;
  font-size: 4.0rem;
  text-transform: uppercase;
  color: white;
  text-align: center;
  width: auto;
  line-height: normal !important;
}

.icone-servico {
  display: inline-block;
  width: 150px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.img-servico {
  width: 100%;
  margin: 25px 0;
  background-color: rgba(255, 255, 255, 0.25);
}

.text-servico {
  text-align: center;
  color: white !important;
  margin-top: 30px;
}

.text-servico p {
  color: white !important;
}

.close-modal {
  background-color: transparent;
  cursor: pointer;
  height: 65px;
  position: absolute;
  right: 30px;
  top: 25px;
  width: 65px;
}

.lr {
  background-color: #fff;
  height: 50px;
  margin-left: 35px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 2px;
  z-index: 1051;
}

.lr .rl {
  background-color: #fff;
  height: 50px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  width: 2px;
  z-index: 1052;
}

.box-artigos-slider {
  position: relative;
  border: 4px solid white;
  width: 100%;
  height: 400px;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.3);
}

.box-artigos-slider .slick-dots {
  bottom: 0;
}

.box-artigos-slider .box-artigo-slider {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.box-artigos-slider .text-artigo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 15px 20px 45px 15px;
  background: rgba(0, 0, 0, 0.5);
}

.box-artigos-slider .text-artigo h2 {
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-family: 'akrobatblack' !important;
  font-size: 1.7rem;
}

.box-artigo-destaque {
  width: 100%;
  height: 170px;
  display: block;
}

.box-artigo-destaque h3 {
  color: white;
  font-family: 'akrobatblack' !important;
  font-size: 1.15rem;
}

.box-artigo-destaque span {
  color: white;
  font-size: .70rem;
}

.box-artigo-destaque p {
  color: rgba(255, 255, 255, 0.8);
  font-family: 'akrobatregular' !important;
  text-transform: uppercase;
  font-size: .75rem;
}

.box-artigo-destaque:first-child {
  margin-bottom: 45px;
}

.box-artigo-destaque .box-img {
  width: 100%;
  height: 170px;
  border: 3px solid white;
  background: url("./../../public/img/_layout/sem_foto.png") center center no-repeat;
  background-size: 80px;
}

.fotos-albuns-resp {
  display: none !important;
  height: 212px !important;
}

@media (max-width: 780px) {
  .icone-servico {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .title-tip-1 {
    font-size: 40px !important;
    line-height: 42px;
    text-align: center;
  }

  .title-tip-1:before {
    display: none !important;
  }

  .box-artigo-destaque {
    height: auto !important;
  }

  .box-artigo-destaque:first-child {
    margin-bottom: 15px !important;
  }

  .fotos-albuns-resp {
    display: block !important;
  }

  .fotos-albuns {
    display: none !important;
  }
}

.ctn-top-header .nav-item {
  position: relative;
}

.ctn-top-header .nav-item:before {
  position: absolute;
  content: ' ';
  display: block;
  width: 0;
  background: #BD3B43;
  height: 4px;
  border-radius: 2px;
  bottom: 10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ctn-top-header .nav-item:before {
    transition: none;
  }
}

.ctn-top-header .nav-item.active {
  position: relative;
}

.ctn-top-header .nav-item.active .nav-link {
  color: #BD3B43 !important;
}

.ctn-top-header .nav-item.active:before {
  transition: 0.3s;
  width: 90%;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ctn-top-header .nav-item.active:before {
    transition: none;
  }
}

.fotos-albuns {
  text-align: center;
  width: 100%;
}

.fotos-albuns .img-album {
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 250px;
  height: 220px;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fotos-albuns .img-album {
    transition: none;
  }
}

.fotos-albuns .img-album:hover {
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fotos-albuns .img-album:hover {
    transition: none;
  }
}

.fotos-albuns .img-album:hover .alt-img {
  top: 0;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fotos-albuns .img-album:hover .alt-img {
    transition: none;
  }
}

.fotos-albuns .img-album .alt-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  background: rgba(61, 63, 146, 0.8);
  text-align: center;
  color: white;
  font-size: 17px;
  transition: 0.3s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fotos-albuns .img-album .alt-img {
    transition: none;
  }
}

.fotos-albuns .img-album .alt-img span {
  position: absolute;
  font-family: 'Gotham-bold';
  font-weight: bold;
  text-shadow: 0px 1.5px 2px black;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.fancybox-overlay,
#fancybox-thumbs {
  z-index: 999999999 !important;
}

