
// Core

@import "../fonts/Gothan/stylesheet.css";
@import "../fonts/Poppins/stylesheet.css";
@import "../fonts/Akrobat/stylesheet.css";

@import "bootstrap/functions";
@import "custom/functions";

@import "custom/variables";
@import "bootstrap/variables";

@import "bootstrap/mixins";
@import "custom/mixins";

// Bootstrap components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

@import "../css/argon.min.css";
// Custom components
//
//@import "custom/reboot.scss";
//@import "custom/global.scss";
//@import "custom/utilities.scss";
//
//@import "custom/accordion.scss";
//@import "custom/alerts.scss";
//@import "custom/avatars.scss";
//@import "custom/badge.scss";
//@import "custom/buttons.scss";
//@import "custom/card.scss";
//@import "custom/carousel.scss";
//@import "custom/close.scss";
//@import "custom/custom-forms.scss";
//@import "custom/dropdown.scss";
//@import "custom/footer.scss";
//@import "custom/forms.scss";
//@import "custom/grid.scss";
//@import "custom/icons.scss";
//@import "custom/input-group.scss";
//@import "custom/list-group.scss";
//@import "custom/modal.scss";
//@import "custom/nav.scss";
//@import "custom/navbar.scss";
//@import "custom/pagination.scss";
//@import "custom/popover.scss";
//@import "custom/progress.scss";
//@import "custom/section.scss";
//@import "custom/separator.scss";
//@import "custom/type.scss";
//
//// Vendor
//
//@import "custom/vendor/bootstrap-datepicker.scss";
//@import "custom/vendor/headroom.scss";
//@import "custom/vendor/nouislider.scss";

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin center() {
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@mixin radialGradient($fromColor, $toColor){
  background: rgba(255,255,57,1);
  background: -moz-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $fromColor), color-stop(100%, $toColor));
  background: -webkit-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
  background: -o-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
  background: -ms-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
  background: radial-gradient(ellipse at center, $fromColor 0%, $toColor 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$fromColor', endColorstr='$toColor', GradientType=1 );
}

@import "mixins";
@import "../css/animate.css";

.fa, .fas, .far, .fal, .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border-radius: .1em;
  border: solid .08em #eee;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fas.fa-pull-left, .far.fa-pull-left, .fal.fa-pull-left, .fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fas.fa-pull-right, .far.fa-pull-right, .fal.fa-pull-right, .fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.fa-stack-1x, .fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

body {
  overflow-x: hidden;
  width: 100vw;
  margin-left: 0 !important;
  margin: 0 !important;
  margin-top: 105px !important;
  background: white;
}

header {
  position: relative;
}

.overlay-search {
  display: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}

.row {
  margin: 0 !important;
}

.align-in-v {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #6b9737;
}

h1 {
  line-height: 36px;
  display: block;
  width: 100%;
  margin-bottom: 0;
  font-family: "oxygenbold", sans-serif;
  font-size: 52px;
  color: white;
}

h6, h4, h5 {
  font-family: "Gotham-bold", sans-serif;
}

p {
  line-height: 18px;
  font-family: "oxygenlight", sans-serif;
  font-size: 16px;
  color: #999;
}

.ls-1 {
  color: #8898aa93 !important;
}

.text-bluebaby small {
  color: #46bdd7 !important;
  font-family: "Gotham-bold", sans-serif;
  font-size: 100% !important;
}

small {
  font-family: "Gotham-book", sans-serif;
  color: #8898aa !important;
}

.gotham-font {
  font-family: "Gotham-book", sans-serif;
}

b, strong {
  font-weight: bold;
}

.text-404 {
  text-shadow: 0 4px 0px #36a2bb, 0 10px 7px rgba(0, 0, 0, 0.35);
  color: #46bdd7;
  font-size: 12em;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 0.6em;
}

.ctn-banner {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #e8e8e8;
}

.slick-list {
  width: 100%;
  height: 100% !important;
}

.slick-track {
  height: 100% !important;
}

.i-bann {
  height: 100% !important;
}

.ctn-side-menu {
  position: fixed;
  left: -100%;
  box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
  top: 0;
  width: 50%;
  height: 100%;
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  transition: left 0.2s;
}

.sidemenu-header {
  width: 100%;
  display: inline-block;
  background: #6b9737;
  padding: 20px 20px;
  color: white;
}

.sidemenu-header .cart-infs {
  color: white;
  margin-top: 2px;
}

.sidemenu-header svg {
  width: 36px;
  height: 36px;
  margin-top: 0;
}

.sidemenu-header svg path {
  fill: white !important;
}

.content-sidemenu .dropdown-item {
  color: #6b9737;
  font-family: "Gotham-bold", Sans-Serif;
  font-size: 15px;
  font-weight: 300;
}

.content-sidemenu .dropdown-item:active {
  background: #f7fafc !important;
}

.sidemenu-open {
  left: 0 !important;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  transition: left 0.3s;
}

.sidemenu-open:after {
  position: absolute;
  content: "Fechar";
  z-index: 99999;
  top: 20px;
  left: calc(105%);
  display: block;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  font-weight: bold;
  font-size: 18px;
}

.sidemenu-open:before {
  position: fixed;
  content: " ";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.wr-side-menu {
  overflow: scroll;
  width: 100%;
  height: 100%;
  background: white;
  padding: 0 0 0 0;
  position: relative;
  z-index: 99999;
}

.dr-it-gray {
  position: relative;
  background: #f1f1f1 !important;
  color: #333 !important;
}

.dr-it-gray i {
  margin-right: 15px;
  font-size: 19px;
}

.dr-it-gray .fa-angle-down {
  position: absolute;
  right: 20px;
  top: 18px;
}

.wr-submenu ul {
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px !important;
  background: #eaeaea !important;
  margin-bottom: 0 !important;
}

.wr-submenu ul a {
  background: #eaeaea !important;
}

.sr-sm {
  margin-top: 15px;
  display: block;
  padding: 0 15px;
}

.content-sidemenu {
  padding-bottom: 20px;
}

.ctn-siderigth-menu {
  position: fixed;
  right: -100%;
  box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
  top: 0;
  width: 50%;
  height: 100%;
  -webkit-transition: right 0.2s;
  -moz-transition: right 0.2s;
  transition: right 0.2s;
}

.sidemenuright-open {
  right: 0 !important;
  -webkit-transition: right 0.3s;
  -moz-transition: right 0.3s;
  transition: right 0.3s;
}

.sidemenuright-open:after {
  position: absolute;
  content: "Fechar";
  z-index: 99999;
  top: 20px;
  right: calc(105%);
  display: block;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  font-weight: bold;
  font-size: 18px;
}

.sidemenuright-open:before {
  position: fixed;
  content: " ";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.sidemenuright-header {
  width: 100%;
  display: inline-block;
  font-family: "Gotham-bold", Sans-Serif;
  background: #6b9737;
  text-align: center;
  padding: 15px 15px;
  color: white;
}

@media (min-width: 993px) {
  .ctn-side-menu, .ctn-siderigth-menu {
    display: none;
  }
}

@media (max-width: 620px) {
  .ctn-side-menu, .ctn-siderigth-menu {
    width: 65%;
  }
}

@media (max-width: 480px) {
  .ctn-side-menu, .ctn-siderigth-menu {
    width: 75%;
  }
}

@media (max-width: 340px) {
  .ctn-side-menu, .ctn-siderigth-menu {
    width: 85%;
  }
}

.ctn-top-header {
  position: fixed;
  height: 124px;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 14px rgba(0, 0, 0, 0.88);
  z-index: 13;
  background-color: #fe8100;
}

.ctn-header {
  position: relative;
  z-index: 12;
  background-color: #fe8100;
  height: 124px;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ctn-top-header .row {
  justify-content: flex-end;
  align-items: center;
}

.ctn-top-head-mobile .btn-mult-content {
  padding: 0.35rem 0.8rem;
}

.ctn-top-head-mobile .box-cart {
  margin: 0 !important;
  height: auto !important;
}

.btn-cart-mob {
  margin-right: 10px;
  position: relative;
  margin-top: -6px;
}

.btn-cart-mob div {
  position: absolute;
  padding: 0 5px;
  border: 3px solid #fe8100;
  min-width: 29px;
  height: 29px;
  border-radius: 16px;
  background: #46bdd7;
  bottom: -8px;
  right: 50%;
}

.btn-cart-mob div span {
  position: relative;
  font-family: "Gotham-bold", Arial, sans-serif;
  width: 100%;
  text-align: center;
  display: block;
  line-height: 25px;
  color: white;
  font-size: 14px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn-user-mob i, .btn-cart-mob i {
  font-size: 32px;
  color: #6b9737;
}

@media (max-width: 480px) {
  .btn-search-mob {
    width: 35px !important;
    height: 35px !important;
  }

  .btn-search-mob i {
    font-size: 16px !important;
  }

  .btn-user-mob i, .btn-cart-mob i {
    font-size: 24px;
    color: #6b9737;
  }

  .hamb-wrapper p {
    font-size: 11px !important;
  }

  .col-btns-mob {
    padding-left: 0 !important;
  }

  .box-menu {
    padding-right: 1rem !important;
    padding-left: 20px !important;
  }
}

.box-logo {
  width: 300px;
  height: 100%;
}

.box-logo img {
  position: absolute;
  top: 50%;
  left: 0;
  @include transform(translateY(-50%));
  max-width: 100%;
  max-height: 95%;
}

.btn-rs {
  margin: 0 3px;
  color: white;
  font-size: 29px;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.box-cart {
  position: relative;
  cursor: pointer;
  height: 50px;
  float: right;
  margin: 0 0 0 15px;
}

.cart-img {
  float: left;
  margin-top: 3px;
}

.wrapp-rs {
  margin-right: 10px;
}

.cart-infs {
  position: relative;
  margin-top: 0;
  height: 29px;
  margin-bottom: 0;
  margin-left: 10px;
  line-height: 14px;
  text-align: left;
  float: left;
  color: #212121;
  font-family: "Gotham-book", sans-serif;
}

.num-itens {
  font-family: "Gotham-bold", sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 1px;
}

.cart-infs .total {
  font-size: 13px;
}

.badge-danger {
  color: #f80031;
  background-color: #fbafbe;
}

.btn .badge-floating {
  position: absolute;
  left: -15px;
  top: 0;
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  transform: translateY(-40%);
  border: 3px solid #fff;
  width: 25px;
  padding: 0;
  height: 25px;
  font-size: 0.79rem;
  margin: 0 !important;
}

.btn .badge-floating i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar-brand {
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  color: white !important;
  height: 124px;
  line-height: 124px;
  font-family: "Gotham-bold", sans-serif;
  margin-right: 30px !important;
  font-size: 24px !important;
}

.navbar-brand:not(.selected):hover {
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.ctn-header .nav-item {
  margin-right: 0 !important;
  padding: 0 15px;
  border-right: 1px solid #6296ca;

}



.ctn-header .nav-item:last-child {
  border-right: none;
}

.ctn-header .nav-item .nav-link {
  font-family: "Gotham-book", sans-serif;
  font-size: 18px !important;
}

.line-selector {
  width: 20px;
  opacity: 0;
  bottom: 0;
  height: 6px;
  background: #46bdd7;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.line-selector:before {
  position: absolute;
  z-index: -5;
  bottom: 100%;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  transform: translate(-50%, 0.3rem) rotate(-45deg);
  border-radius: 0.1rem;
  background: #46bdd7;
  box-shadow: none;
}

.ctn-header .navbar-brand.selected .line-selector, .ctn-header .navbar-brand .line-selector:hover {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  opacity: 1;
  width: 80px;
}

.ctn-footer {
  height: 490px;
  background-color: #222;
}

.ctn-fot_rights {
  height: auto;
  background-color: #6b9737;
}

.t-foot {
  position: relative;
  margin: 0;
  font-size: 13px;
  color: white;
  text-align: center;
}

.ps-lk {
  display: block;
  position: absolute;
  right: 0;
  width: 20px;
  height: 19px;
  background: url("../img/_layout/icone_branco.png") center center no-repeat;
  background-size: contain;
}

.cont-fot {
  opacity: 0.5;
}

.ctn-fot_rights {
  position: relative;
  z-index: 2;
  padding: 26px 0;
  background: white;
}

.text-copy {
  position: relative;
  padding-bottom: 5px;
  margin: 0 !important;
  width: 100%;
  font-size: 18px;
  color: #3f2814;
  text-align: center;
}

.ic-ps {
  position: relative;
  margin-left: 10px;
  top: 5px;
  display: inline-block;
  width: 21px;
  height: 20px;
  background: url("../img/_layout/ps_logo.png") no-repeat;
  background-size: contain;
}

.align-in-v {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.align-in-vh {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.foot {
  width: 100%;
  background: #fe8100;
  padding: 110px 0 90px 0;
}

.rs-footer {
  position: absolute;
  width: 202px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  padding: 10px 15px;
  background: #fe8100;
}
/*
.line-back-rs {
  position: relative;
  margin-bottom: 60px;
  display: inline-block;
  width: 483px;
  background: url("../img/_layout/point_ic.png") repeat-x;
  height: 4px;
  max-width: 100%;
}*/

.box-inf-foot.text-right {
  position: relative;
}

.box-inf-foot.text-left:before {
  position: absolute;
  content: " ";
  display: block;
  left: 0;
  width: 1px;
  height: 100%;
  top: 0;
  background: white;
}

.box-inf-foot {
  color: white;
  font-family: "Gotham-book", sans-serif;
  padding-top: 25px;
  padding-bottom: 25px;
}

.img-infs-foot {
  float: left;
  margin-top: 40px;
  position: relative;
  max-width: 100%;
}

.img-logo-foot {
  position: relative;
  max-width: 95%;
  margin-bottom: 20px;
  top: 35px;
}

.wrapp-top_fot {
  position: relative;
  width: 100%;
  height: 66px;
  margin-top: 50px;
}

.box-logo_fot, .line-fot {
  float: left;
  width: 33.33333333333333%;
}

.box-logo_fot {
  position: relative;
  text-align: center;
}

.line-fot {
  position: relative;
  height: 2px;
  background-color: #444;
}

.wrpp-main_fot {
  margin-top: 55px;
  margin-bottom: 40px;
}

.group-min {
  float: left;
  width: 40%;
  margin-top: 20px;
}

.box-inst {
  float: left;
  width: 50%;
}

.box-duv {
  float: left;
  width: 50%;
}

.title-foot {
  font-family: "Myriad Pro", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 15px;
  color: #bd3b3e;
  font-size: 18px;
}

.nav-foot {
  width: 100%;
}

.it-foot {
  display: block;
  font-family: Arial, sans-serif;
  color: #202020;
  font-size: 15px;
  width: 100%;
  margin-top: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.it-foot:hover {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  color: #bd3b3e;
  text-decoration: none;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
}

.lk-foot {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.newsletter-box {
  margin-bottom: 60px;
}

.newsletter-text {
  position: relative;
  transform: translateY(4px);
  -webkit-transform: translateY(4px);
  color: white;
  display: block;
  text-align: left;
  margin-right: 20px;
  font-family: "Gotham-book", Sans-Serif;
  font-size: 22px;
}

.newsletter-text span {
  font-weight: bold;
}

.form-newsletter {
  position: relative;
  width: 495px;
  max-width: 100%;
  height: 46px;
  border-radius: 23px;
  background: white;
  display: inline-block;
}

.form-newsletter input {
  width: 100%;
  height: 100%;
  padding-left: 20px !important;
  padding-right: 140px !important;
  color: #aaa;
  background: transparent !important;
  float: none !important;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  font-size: 16px;
  border: none !important;
}

.form-newsletter input::placeholder {
  color: #aaa;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.btn-rounded-form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  display: inline-block;
  padding: 8px 25px 6px 25px;
  color: white !important;
  font-family: "Gotham-bold", Sans-Serif;
  text-decoration: none !important;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 19px;
  margin: 0 5px;
  background: #46bdd7;
}

.btn-rounded-form:hover {
  border-bottom: 2px solid #46bdd7;
}

.btn-rounded-form:active {
  transform: translateY(-50%) scale(0.9);
  transition: 0.1s;
  border-bottom: none;
  border-top: 2px solid #46bdd7;
}

.menu-footer {
  transform: translateX(80px);
  width: 100%;
  margin: 0 auto !important;
}

.menu-footer a {
  font-family: "Geomanist", sans-serif;
  font-size: 13px;
  display: block;
  margin-bottom: 12px;
  color: white;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  text-align: left;
}

.menu-footer a:hover {
  color: rgba(236, 233, 233, 0.788);
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.gray-border-serrated {
  position: relative;
}

//.gray-border-serrated:before {
//  position: absolute;
//  content: " ";
//  width: 100%;
//  background: url("../img/_layout/gray_border.png") repeat-x;
//  height: 38px;
//  display: block;
//  left: 0;
//  top: 0;
//}

.gray-border-serrated-bottom {
  position: relative;
}

//.gray-border-serrated-bottom:before {
//  position: absolute;
//  content: " ";
//  width: 100%;
//  background: url("../img/_layout/bluegray_border.png") repeat-x;
//  height: 38px;
//  display: block;
//  left: 0;
//  bottom: -38px;
//}

*.btn:active, .btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none !important;
}

.btn-round {
  border-radius: 22px;
  font-size: 15px;
  font-family: "Gotham-book", sans-serif;
}

.btn-rs-circle {
  width: 34px;
  border: 1px solid transparent;
  height: 34px;
  background: white;
  margin: 0 10px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.15s;
  -webkit-transition: 0.15s;
}

.btn-rs-circle:hover {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.41), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-rs-circle:active {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  border-color: white;
  box-shadow: none;
  background: #d3e3ed;
  transition: 0.1s;
  -webkit-transition: 0.1s;
}

.btn-rs-circle .fab {
  display: block;
  position: relative;
  margin-left: -1px;
  color: #fe8100;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.btn:not(.btn-link):not(.btn-outline-default):not(.btn-lg) {
  border: 2px solid transparent;
  padding: 0.35rem 1.25rem;
  outline: none !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-bluebaby {
  background: #46bdd7;
  color: #fff;
}

.btn-bluebaby:hover {
  background: #46bdd7;
  color: #fff;
}

.btn-bluebaby:active {
  border-color: #46bdd7;
  background: #36a2bb !important;
}

.btn-mult-content {
  padding: 0.35rem 1rem;
  display: inline-block;
  border-radius: 0.375rem;
}

.btn-mult-content:hover {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-mult-content:active {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  background: #e8e8e8 !important;
  box-shadow: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.btn-square {
  width: 50px;
  height: 50px;
  padding: 0 !important;
}

.btn-link {
  color: #6b9737;
  padding-left: 0;
  padding-right: 0;
}

.btn-link:hover {
  color: #48682a;
}

.dropdown-menu:not(.datepicker-dropdown) {
  position: absolute;
  padding-top: 0;
  padding-bottom: 0;
  right: 0;
  left: auto;
  border-radius: 0.4375rem;
}

.dropdown-menu:not(.dropdown-menu-right):not(.datepicker-dropdown) {
  min-width: 420px;
}

.dropdown-menu.show {
  animation: show-navbar-dropdown 0.25s ease forwards;
  pointer-events: auto;
  opacity: 1;
}

.dropdown-menu.close {
  display: block;
  animation: hide-navbar-dropdown 0.15s ease backwards;
}

.dropdown-menu h4 {
  font-size: 0.98rem !important;
}

.open-dropdown.checked {
  transform: translateY(-1.4px);
  -webkit-transform: translateY(-1.4px);
  background: #e8e8e8 !important;
  box-shadow: none;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.dropdown-menu .list-group-item .col-auto {
  padding: 0 10px;
}

.avatar {
  border-radius: 0.375rem;
  width: 60px;
  height: 60px;
}

.dropdown-menu:not(.datepicker-dropdown):before {
  position: absolute;
  z-index: -5;
  bottom: 100%;
  right: 30px;
  left: auto;
  display: block;
  width: 18px;
  height: 18px;
  content: "";
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .box-cart {
    position: static;
  }

  .dropdown-menu {
    position: absolute;
    right: auto;
    left: 3%;
    width: 94%;
    min-width: auto;
  }

  .dropdown-menu:before {
    display: none;
  }
}

@keyframes show-navbar-dropdown {
  0% {
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }
  to {
    transform: translate(0, 10px);
    opacity: 0;
  }
}

.icon-tooltip {
  font-family: "Arial", sans-serif;
  border: solid 1px #36a2bb;
  border-radius: 10px;
  color: #36a2bb;
  cursor: pointer;
  display: inline-block;
  font-size: 9px;
  line-height: 9px;
  margin-left: 8px;
  padding: 3px 6px;
  position: relative;
  -webkit-transform: translateY(-3.5px);
  -moz-transform: translateY(-3.5px);
  transform: translateY(-3.5px);
  z-index: 2;
}

.modal-search .modal-dialog {
  max-width: 800px;
  align-items: flex-start;
}

.modal-search .modal-footer {
  justify-content: center;
}

.modal-search.fade .modal-dialog {
  transition: transform 0.15s ease-out;
}

.modal-search .modal-body {
  padding: 0 1.5rem !important;
}

.modal-footer {
  background: #f7fafc !important;
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}

@media (min-width: 576px) {
  .modal-espiar .modal-dialog {
    width: 1000px;
    max-width: 90%;
  }
}

.slider-img {
  position: relative;
  width: 80% !important;
  float: left;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 416px;
}

.slider-img .badge {
  position: absolute;
  bottom: 0;
  left: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slider-img-group {
  width: 20% !important;
  float: left;
  margin: 0 !important;
  width: 90px;
  max-height: 416px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 15px;
}

.item-group-galeria {
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  margin-bottom: 14px;
  height: 90px;
}

.it-min-sel {
  opacity: 0.4;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.img-galeria-d {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.item-galeria-d {
  position: relative;
  min-width: 100%;
  height: 100%;
}

.img-galeria-gr-d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.prod-page .slider-img-group {
  max-height: 450px;
}

.prod-page .slider-img {
  height: 450px;
}

.btn-absolute {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-estatico {
  position: relative;
  width: 100%;
  height: 360px;
  margin-top: 30px;
  padding: 90px 0;
  border: 1px solid #e8e8e8;
  text-align: center;
}

.list-boxes {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
  width: 100%;
}

.wrapper-prod {
  padding: 10px;
  width: 285px;
}

.box-prod {
  position: relative;
  height: 390px;
  //padding: 17px;
  background: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  span {
    background: #f4f4f4;
    color: black;
    font-size: .75rem;
    padding: 5px 20px;
    display: block;
    width: 100%;
  }
  .nome-prod {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin-bottom: 0 !important;
    bottom: 0;
    left: 0;
    color: white !important;
    text-transform: uppercase;
    font-family: 'akrobatblack', sans-serif !important;
    padding: 10px 15px;
  }
}

.box-prod:hover {
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.img-prod {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    position: absolute;
    height: 100%;
    border-radius: 4px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.1) 50%,#000 100%);
    content: '';
    opacity: .7;
    transition: opacity .5s;
  }
}

.img-prod img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}

.nome-prod {
  font-weight: bold;
  margin-bottom: 0;
  color: black;
  text-align: center;
}

.desc-prod {
  font-size: 13px;
  text-align: center;
  color: black;
}

.container-promo {
  z-index: 2;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 20px;
}

.preco-prod-box {
  font-family: "Gotham-bold", sans-serif;
  font-size: 16px;
  text-align: center;
}

.preco-prod {
  font-size: 22px;
  color: #6b9737;
}

.preco-prod-old {
  color: #b4b2b2 !important;
  text-decoration: line-through;
}

.box-btns-prod {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
}

.btn-prod {
  position: relative;
  cursor: pointer;
  text-align: center;
  float: left;
  width: 50%;
  color: #858585;
  font-size: 14px;
  padding: 10px 5px;
  box-sizing: border-box;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.btn-prod .fa {
  font-size: 18px;
}

.btn-prod:hover {
  color: black;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.content-btn-prod {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
}

.zoomContainer {
  z-index: 99999999999 !important;
}

.page-link {
  background: transparent;
  color: gray !important;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

.card-columns {
  margin-top: 25px;
}

.card-columns .card, .card-conta, .card-deck .card {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: none;
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
}

.card-columns .card:hover, .card-conta:hover, .card-deck .card:hover {
  -webkit-transition: box-shadow 0.15s ease;
  -moz-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.m-timestamps svg {
  display: block;
  position: absolute;
  top: 2px;
  fill: #c5c5c5;
  display: inline-block;
  margin-right: 11px;
  width: 14px;
  height: 14px;
}

.m-timestamps {
  width: 100%;
  margin: 20px 0;
  padding: 0 0 15px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.75);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  float: right;
  padding-left: 0;
}

.m-timestamps time {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 18px;
  color: #c5c5c5;
  font-family: "Gotham-bold", sans-serif;
  font-size: 0.858rem;
  font-weight: 400;
  padding-left: 25px;
  text-align: left;
  display: block;
}

.figure-img {
  max-width: 100%;
}

.art-body {
  line-height: 24px;
  font-family: "Gotham-book", sans-serif;
  font-size: 18px;
  color: #999;
  width: 100%;
}

.art-body p, .card-text {
  font-weight: 100;
  font-size: 13px;
  color: #4e4e4e;
}

.art-body img {
  max-width: 100%;
  display: inline-block;
  padding: 25px 0;
}

.card-title {
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.ctn-artigo {
  margin: 0 auto !important;
}

.title-art {
  font-size: 3rem;
  text-align: left;
  color: black;
  line-height: 50px;
}

.card-deck {
  margin-top: 35px;
}

.sec-coments {
  width: 100%;
  margin-top: 35px;
}

.m-comments .fb-comments, .m-comments .fb-comments * {
  width: 100% !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6b9737 !important;
  background-color: #6b9737 !important;
}

.form-control {
  font-family: "Gotham-book", sans-serif;
}

.o-closed {
  display: none;
}

.input-group-text {
  padding: 0.625rem 1.1rem;
}

.input-erro {
  position: relative;
  border: 1px solid #f75676 !important;
}

.input-erro:before {
  position: absolute;
  display: block;
  padding-left: 35px !important;
  content: "campo incorreto";
  right: 25px;
  border-bottom-left-radius: 2px;
  font-family: "Gotham-book", sans-serif !important;
  color: #f75676;
  font-size: 15px;
  background: url(../img/_layout/exclamation-sign.svg) white 15px center no-repeat;
  background-size: 14px;
  padding: 0 15px;
  height: 20px;
  top: -12px;
}

.input-erro-bottom .input-group {
  border: 1px solid #fb6340 !important;
}

.input-erro-bottom .invalid-feedback {
  display: block;
}

.invalid-feedback {
  margin-top: 0.45rem;
  padding: 0 0.5rem;
}

.input-disabled {
  background: #eceff1 !important;
}

input:disabled {
  cursor: not-allowed;
}

.alert {
  padding: 0.4rem 0.9rem;
}

.alert-dismissible {
  padding-right: 2.5rem;
}

.alert-dismissible .close {
  right: 0.9rem;
  margin-top: 2px;
}

.alert-icon {
  margin-right: 0.9rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6b9737;
  background-color: #6b9737;
}

.divider-right {
  border-right: 1px solid #e9ecef;
}

.divider-left {
  border-left: 1px solid #e9ecef;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #fc9733;
}

.nav-pills .nav-item {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
}

.nav-pills .nav-link {
  color: #8898aa !important;
  font-weight: bold;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.tab-tipo.closed {
  display: none;
}

.input-disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.6 !important;
}

.custom-checkbox
.custom-control-input:disabled:checked
~ .custom-control-label::before {
  opacity: 0.6 !important;
}

.status-form-container .alert {
  width: 100%;
}

.loading-form-container {
  flex-grow: 1;
  justify-content: flex-start !important;
}

.loading-box img {
  margin-right: 8px;
}

.ctn-carrinho {
  padding-top: 50px;
  padding-bottom: 70px;
}

.cv-carrinho {
  margin-top: 30px;
  width: 100%;
  display: inline-block;
}

.cv-wrapp-itens {
  width: 75%;
  float: left;
  border-radius: 2px;
  box-sizing: border-box;
  padding-right: 6px;
}

.cv-wrapp-finalizar {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding-left: 25px;
}

.cv-box-img {
  width: 15%;
  float: left;
  text-align: center;
}

.cv-box-img img {
  max-width: 100%;
  max-height: 100%;
}

.cv-box-infs {
  width: 45%;
  float: left;
  padding-left: 20px;
  font-family: "Gotham-book", sans-serif;
}

.cv-box-options {
  display: inline-block;
  width: 40%;
  float: left;
  padding-left: 20px;
}

.cv-text-default {
  font-family: "Gotham-book", sans-serif;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  color: #818181;
}

.cv-text-menor {
  color: rgba(0, 0, 0, 0.3);
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 13px;
}

.cv-text-nome {
  color: #777;
  font-weight: bold;
  margin-bottom: 6px;
  font-family: "Gotham-book", sans-serif;
  font-size: 20px;
}

.cv-text-price-unit, .cv-text-price-subtotal {
  margin-top: 0;
  margin-bottom: 6px;
  color: #818181;
  font-family: "Gotham-book", sans-serif;
  font-size: 18px;
}

.cv-wrapp-item {
  display: inline-block;
  margin-top: -6px;
  width: 100%;
  padding: 20px;
  background: #fcfcfc;
  border: 1px solid #f4f4f4;
}

.form-qtd {
  width: 80px !important;
  height: 40px !important;
  padding-right: 20px;
}

.qtd {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 10px !important;
  outline: none;
  color: #aaa;
  height: 50px;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.qtd:focus {
  border-color: #eb4981;
  transition: 0.2s;
}

.texto-preco-promo {
  text-decoration: line-through;
}

.numberfy-container {
  position: relative;
}

.numberfy-container .btnUp, .numberfy-container .btnDown {
  background-size: auto 6px !important;
  text-align: center;
  position: absolute !important;
  width: 26px;
  right: 2px;
  height: 20px;
  display: block;
}

.numberfy-container .btnUp:hover, .numberfy-container .btnDown:hover {
  background-color: rgba(235, 73, 130, 0.781) !important;
  cursor: pointer;
}

.numberfy-container .btnUp {
  background: #eb4981 url("../img/_layout/arrowNumberUp.png") 6px center no-repeat;
  border-top-right-radius: 20px;
  top: 0;
}

.numberfy-container .btnDown {
  background: #eb4981 url("../img/_layout/arrowNumberDown.png") 6px center no-repeat;
  border-bottom-right-radius: 20px;
  bottom: 0;
}

.cv-box-qtd {
  float: left;
}

.cv-btn-remover {
  float: right;
}

.cv-btn-remover {
  position: relative;
  display: block;
  line-height: 40px;
  margin-left: 40px;
  color: white;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 135px;
  height: 40px;
  border-radius: 20px;
}

.cv-btn-finalizar {
  position: relative;
  display: block;
  line-height: 40px;
  color: white;
  margin-top: 15px;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #5cb85c;
}

.cv-btn-finalizar:hover {
  background-color: #478d47;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cv-btn-finalizar p {
  position: relative;
  margin: 0;
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 15px;
  height: 100%;
  color: white;
  text-align: center;
}

.cv-btn-continuar {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 40px;
  color: white;
  margin-top: 10px;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background-color: #38abe0;
}

.cv-btn-continuar:hover {
  background-color: #2a8ebd;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cv-btn-continuar p {
  position: relative;
  margin: 0;
  font-family: "Gotham-book", sans-serif;
  font-weight: bold;
  font-size: 15px;
  height: 100%;
  color: white;
  text-align: center;
}

.cv-text-subtotal {
  font-family: "Gotham-book", sans-serif;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
  color: #46bdd7;
  font-size: 26px;
}

.cv-fretes {
  margin-top: 20px;
}

.cv-wrapp-frete {
  display: none;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  background: #fcfcfc;
  margin-top: -5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.cv-wrapp-frete:hover {
  background: #f2f2f2;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.cv-frete-cidade {
  float: left;
  min-height: 100%;
  width: 65%;
  padding: 8px;
  font-family: roboto_slabbold, sans-serif;
  border-right: 1px solid #f4f4f4;
  color: #3f1000;
}

.cv-valor-cidade {
  float: left;
  min-height: 100%;
  width: 35%;
  padding: 8px;
  color: #818181;
  text-align: right;
  font-family: roboto_slabbold, sans-serif;
  font-size: 13px;
}

.cv-text-frete {
  display: none;
  margin-bottom: 10px;
}

.empty-cart {
  width: 100%;
  border-radius: 2px;
  border: 3px dashed rgba(236, 48, 56, 0.3);
  padding: 7px 10px;
  background: rgba(236, 48, 56, 0.25);
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: white;
}

.wrapper-box-h:last-child {
  padding-left: 10px !important;
}

.box-filters-mobile {
  max-width: 100% !important;
  border-radius: 0 !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100%;
}

.alert-text a {
  color: white;
  opacity: 0.65;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.alert-text a:hover {
  opacity: 0.4;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.btn-add-cart .loading-box {
  position: absolute;
  top: 50%;
  margin-top: 0 !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 100%;
  padding-left: 15px;
}

.empty-cart-text {
  width: 100%;
  font-family: "Gotham-book", sans-serif;
  text-align: center;
  font-size: 20px;
  color: #48682a;
  margin-top: 15px;
  font-weight: bold;
  padding: 10px 25px;
}

.empty-cart-img {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.box-empty-cart-pg .empty-cart-img {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.box-empty-cart-pg, .box-empty-cart {
  opacity: 0.25;
  margin-top: 35px;
}

.alert-text {
  display: inline !important;
  vertical-align: middle;
}

.custom-toggle-white input:checked + .custom-toggle-slider {
  border-color: #fff;
}

.custom-toggle-white input:checked + .custom-toggle-slider:before {
  background: #fff;
}

.custom-toggle-white input:checked + .custom-toggle-slider:after {
  color: #fff;
}

.custom-toggle-slider:after {
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  overflow: hidden;
  min-width: 1.66667rem;
  margin: 0 0.21667rem;
  content: attr(data-label-off);
  transition: all 0.15s ease;
  text-align: center;
  color: #ced4da;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
  right: auto;
  left: 1px;
  content: attr(data-label-on);
  color: white;
}

.custom-toggle {
  position: relative;
  display: inline-block;
  width: 57px;
  height: 1.5rem;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(2.01rem);
}

.form-secondary .focused .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.form-secondary .input-group-alternative {
  border-radius: 0.375rem !important;
}

.form-secondary .input-group-alternative .form-control {
  padding: 0.625rem 0.75rem !important;
}

.form-secondary .focused .input-group-text {
  color: #fff !important;
  border-right: 1px solid rgba(72, 104, 42, 0.95) !important;
  background-color: rgba(72, 104, 42, 0.6) !important;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.form-secondary .input-group-alternative .form-control {
  color: #fff !important;
  background-color: rgba(72, 104, 42, 0.45);
  border: 1px solid rgba(72, 104, 42, 0.45) !important;
}

.form-secondary .input-group-alternative .form-control:focus {
  color: #fff !important;
  border: 1px solid rgba(72, 104, 42, 0.95) !important;
  background-color: rgba(72, 104, 42, 0.6);
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.form-secondary
.input-group-alternative
.form-control::-webkit-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control:-moz-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control::-moz-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-alternative .form-control:-ms-input-placeholder {
  color: #fff !important;
}

.form-secondary .input-group-text {
  color: #fff;
  border-right: 1px solid rgba(72, 104, 42, 0.45) !important;
  background-color: rgba(72, 104, 42, 0.45) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, rgba(48, 203, 238, 0.75) 0, rgba(109, 172, 34, 0.7) 100%) !important;
}

.modal[role="dialog"] {
  background: #000000bf;
}

.modal-dialog {
  padding: 15px 15px 35px 15px;
}

.blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  filter: blur(10px);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.border-end-main {
  border: 1px solid #ffd600;
  background: #fff;
  position: relative;
  padding-bottom: 40px !important;
}

.border-end-main:after {
  position: absolute;
  content: "endereÃ§o principal (fornecido no cadastro)";
  font-weigth: bold;
  color: white;
  width: 100%;
  background: #ffd600;
  font-size: 11px;
  padding: 5px 10px;
  display: block;
  right: 0;
  bottom: 0;
}

.border-end-default {
  border: 1px solid #2bffc6;
  position: relative;
  padding-bottom: 40px !important;
}

.border-end-default:after {
  position: absolute;
  content: "endereÃ§o escolhido como padrÃ£o para as prÃ³ximas compras";
  font-weigth: bold;
  color: white;
  width: 100%;
  background: #2bffc6;
  font-size: 11px;
  padding: 5px 10px;
  display: block;
  right: 0;
  bottom: 0;
}

.checkout-header {
  background: #edf0f3 !important;
  padding: 30px 0 0 0;
}

.checkout-title {
  font-size: 20px;
  color: #bbbdc0 !important;
  font-weight: bold;
  letter-spacing: 0.0625rem;
  font-family: "Gotham-bold", sans-serif;
}

.wrapper-checkout-header {
  display: block;
}

.btn-status-checkout {
  z-index: 1;
  position: relative;
  padding: 2.5px 8px 7px 17px !important;
  border-radius: 24.5px;
  border-color: transparent !important;
  letter-spacing: 0.0625rem;
  font-family: "Gotham-bold", sans-serif;
  background: #cacccf !important;
  font-size: 13px !important;
  box-shadow: none !important;
}

.btn-status-checkout:active {
  background: #cacccf !important;
  border-color: transparent !important;
}

.btn-status-checkout:hover {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}

.btn-status-checkout.active {
  z-index: 2;
  background: #48682a !important;
  color: white !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;
}

.btn-status-checkout.concluido {
  z-index: 3;
  background: #2dce89 !important;
  color: white !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 5px 5px rgba(50, 50, 93, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;
}

.btn-status-checkout .btn-inner--text {
  margin-left: 5px !important;
}

.btn-status-checkout .btn-inner--icon {
  font-size: 20px !important;
}

.btn-status-checkout.line-lr {
  margin-left: 20px;
  margin-right: 20px;
}

.btn-status-checkout.line-l {
  margin-left: 20px;
}

.btn-status-checkout.line-r {
  margin-right: 20px;
}

.btn-status-checkout .line-right {
  z-index: 1;
  width: 25px;
  height: 7px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  left: 100%;
  display: block;
  background: #cacccf;
}

.btn-status-checkout .line-left {
  z-index: 1;
  width: 25px;
  height: 7px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  right: 100%;
  display: block;
  background: #cacccf;
}

.btn-status-checkout .line-right:before, .btn-status-checkout .line-left:before {
  position: absolute;
  content: " ";
  display: block;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background: #cacccf;
}

.btn-status-checkout.active .line-right:before, .btn-status-checkout.active .line-left:before {
  width: 100%;
  background: #48682a;
}

.btn-status-checkout.concluido .line-right:before, .btn-status-checkout.concluido .line-left:before {
  width: 100%;
  background: #2dce89;
}

.concluido-sn {
  display: none;
}

.btn-status-checkout.concluido .concluido-sn {
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: block;
  border-radius: 50%;
  position: absolute;
  width: 20px;
  font-size: 10px;
  height: 20px;
  color: white;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  right: -20px;
  background: #2dce89;
}

.btn-status-checkout.concluido .concluido-sn i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pg-processado {
  align-items: center;
  justify-items: center;
  margin-top: 30px !important;
}

.pg-processado img {
  max-width: 200px;
  height: auto;
  margin-left: 10px;
}

.text-conexao {
  color: #2dce89 !important;
}

.pg-compra-segura {
  align-items: center;
  justify-items: center;
}

.pg-compra-segura .text-conexao {
  border-radius: 4px;
  display: inline-block;
  background: #2dce89 !important;
  color: white !important;
}

.link-effect {
  position: relative;
  color: #8898aa !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect:before {
  position: absolute;
  content: "";
  display: block;
  bottom: -8px;
  height: 2px;
  width: 0;
  left: 0;
  background: #8898aa !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect i {
  margin-left: 6px;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.link-effect:hover {
  color: #64707c !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.link-effect:hover i {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  transform: translateX(4px);
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.link-effect:hover:before {
  width: 100%;
  background: #64707c !important;
  -webkit-transition: 0.15s;
  -moz-transition: 0.15s;
  transition: 0.15s;
}

.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0 !important;
}

.list-item-checkout {
  padding-bottom: 15px !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f7fc;
}

.list-item-checkout:last-child {
  border: none !important;
  padding: 0 !important;
}

.nav-pills .nav-link, .nav-pills .nav-link {
  font-size: 25px !important;
  line-height: 25px;
}

.nav-pills .nav-link i, .nav-pills .nav-link i {
  font-size: 50px !important;
  display: block;
  margin-bottom: 5px;
}

.card-flag {
  position: relative;
  display: inline-block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 56px;
  height: 40px;
  vertical-align: middle;
  margin-right: 10px;
}

.text-card-code {
  padding-left: 70px;
  position: relative;
  display: inline-block;
  margin-top: 4px;
  color: #525f7f;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.etapa-red {
  background: #f5365c !important;
}

.etapa-red .concluido-sn {
  box-shadow: 0 4px 8px rgba(50, 50, 93, 0.15), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: block;
  border-radius: 50%;
  position: absolute;
  width: 20px;
  font-size: 10px;
  height: 20px;
  color: white;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  right: -20px;
  background: #2dce89;
}

.etapa-red .concluido-sn i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.etapa-red .concluido-sn {
  background: #f5365c !important;
}

.copy-text {
  cursor: pointer;
}

.favorito {
  color: #f5365c !important;
}

.bar-filtros {
  background: #f4f4f4 !important;
  width: 100%;
  border-radius: 6px;
}

.bar-filtros .open-sideright {
  display: none;
}

.breadcrumb-item i {
  font-size: 16px;
  margin-right: 5px;
}


.breadcrumb-item {
  font-size: 13px;
  font-family: "Gotham-bold", sans-serif;
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  content: "-";
  color: #8898aa;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  a {
    color: $secondary !important;
    &:hover {
      color: rgba($secondary, .5) !important;
    }
  }
  &.bc-primary {
    a {
      color: $primary !important;
      &:hover {
        color: rgba($primary, .5) !important;
      }
    }
  }
}

a {
  text-decoration: none;
  color: #46bdd7;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a:hover {
  color: #36a2bb;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a.dropdown-item:active {
  background: #36a2bb;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

a.dropdown-item:first-child {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

a.dropdown-item:last-child {
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}

.pagination {
  justify-content: center;
}

.dropdown-divider {
  border-top: 1px solid #edf2f7;
}

.bc-item {
  font-family: "Gotham-book", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #989898;
  padding: 0 7px 0 5px;
  display: inline-block;
}

.bc-item-m {
  font-family: "Gotham-book", sans-serif;
  font-size: 12px;
  position: relative;
  text-decoration: none !important;
  margin-left: 0;
  font-weight: bold;
  background: transparent;
  border-radius: 10px;
  color: #989898 !important;
  padding: 0 28px 0 0;
  display: inline-block;
}

.remove-bc-attr {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #dadada;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.bc-item-m .remove-bc-attr:after {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -1px;
  transform: translate(-50%, -50%);
  font-family: "Font Awesome 5 Free";
  top: 5.5px;
  left: 9px;
  display: block;
  content: "\f00d";
  width: 10px;
  height: 10px;
}

.bc-item-m .remove-bc-attr:hover {
  background: #bd3b3e;
}

.bc-item-m .remove-bc-attr:hover:after {
  color: white;
}

.bc-item-attr {
  display: inline-block;
}

@media (max-width: 760px) {
  .card-columns {
    column-count: 2 !important;
  }
}

@media (max-width: 578px) {
  .card-columns {
    column-count: 1 !important;
  }

  .card-columns .card {
    width: 100%;
    float: left;
  }
}

@media (max-width: 480px) {
  .card-columns .card {
    width: 100%;
    float: left;
  }
}

.ctn-top-head-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 8px rgba(50, 50, 93, 0.08), 0 1px 14px rgba(0, 0, 0, 0.88) !important;
  z-index: 13;
  background-color: white !important;
  height: 80px;
}

@media (max-width: 992px) {
  .title-tip-1 {
    font-size: 40px !important;
  }
  .ctn-top-head-mobile {
    display: block;
  }

  .ctn-header {
    display: none !important;
  }

  .ctn-top-header {
    display: none !important;
  }

  .list-boxes {
    justify-content: center !important;
  }

  body {
    margin-top: 80px !important;
  }

  .container {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
}

.ctn-float-btn {
  display: block;
  position: fixed;
  bottom: -100px;
  z-index: 11;
  right: 30px;
  width: 45px;
  height: 45px;
  transition: 0.3s;
}

.float-btn-effect {
  bottom: 45px;
  transition: 0.35s;
}

.ctn-float-btn {
  display: block;
  position: fixed;
  bottom: -100px;
  z-index: 155;
  right: 30px;
  width: 45px;
  height: 45px;
  transition: 0.3s;
}

.ctn-float-btn-file-mob, .ctn-float-btn-file {
  display: block;
  text-decoration: none !important;
  position: fixed;
  bottom: 50px;
  z-index: 11;
  left: 30px;
  width: auto;
  transition: 0.3s;
}

.ctn-float-btn-file .float-btn, .ctn-float-btn-file-mob .float-btn {
  font-family: "chau_philomene_oneregular", sans-serif;
  line-height: 38px;
  height: 38px;
  border-radius: 18.5px !important;
  padding: 0 20px;
  background: #46bdd7;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
}

.float-btn-effect {
  bottom: 45px;
  transition: 0.35s;
}

.float-btn {
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  position: relative;
  background: white;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  z-index: 11;
  color: #909090;
  font-size: 20px;
}

.float-btn:hover {
  transition: 0.3s;
  background: #fc9733;
}

.ctn-float-btn-file .float-btn:hover {
  transition: 0.3s;
  background: #38abe0;
  color: white !important;
}

.wrapper-lines-up {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
}

.wrapper-lines-up span {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: 3px;
  transition: 0.15s;
  background-color: #fc9733;
  border-radius: 2px;
}

.wrapper-lines-up span:nth-of-type(1) {
  -webkit-transform: translate3d(-4px, 4px, 0) rotate(-45deg) scaleX(0.6);
  transform: translate3d(-4px, 4px, 0) rotate(-45deg) scaleX(0.6);
}

.wrapper-lines-up span:nth-of-type(2) {
  -webkit-transform: translate3d(4px, 4px, 0) rotate(45deg) scaleX(0.6);
  transform: translate3d(4px, 4px, 0) rotate(45deg) scaleX(0.6);
}

.wrapper-lines-up span:nth-of-type(3) {
  width: 3px;
  height: 18px;
  left: 50%;
  -webkit-transform: translate3d(-50%, 3px, 0) rotate(0deg);
  transform: translate3d(-50%, 3px, 0) rotate(0deg);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span {
  background: white;
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(3) {
  height: 26px;
  -webkit-transform: translate3d(-50%, 0px, 0) rotate(0deg);
  transform: translate3d(-50%, 0px, 0) rotate(0deg);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(1) {
  -webkit-transform: translate3d(-4px, 0px, 0) rotate(-45deg) scaleX(0.6);
  transform: translate3d(-4px, 0px, 0) rotate(-45deg) scaleX(0.6);
  transition: 0.15s;
}

.float-btn:hover .wrapper-lines-up span:nth-of-type(2) {
  -webkit-transform: translate3d(4px, 0px, 0) rotate(45deg) scaleX(0.6);
  transform: translate3d(4px, 0px, 0) rotate(45deg) scaleX(0.6);
  transition: 0.15s;
}

.box-tipo-mob:first-child {
  border-right: 1px solid #6b9737;
}

.box-tipo-mob {
  text-align: center;
  padding: 10px 15px;
  background: #74a63d;
  color: white;
  font-family: "Gotham-bold", Sans-Serif;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-tipo-mob:hover, .box-tipo-mob:active {
  background: #6b9737 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-tipo-mob.selected {
  background: #46bdd7 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-menu, .box-search {
  width: auto;
  height: 100%;
}

.btn-search-mob {
  position: relative;
  margin: 0 !important;
}

.box-logo-mob {
  width: 100%;
  height: 100%;
  background: url("../img/_layout/logo.png") center center no-repeat;
  background-size: contain;
  justify-content: center;
}

@media (max-width: 520px) {
  .box-logo-mob {
    background: url("../img/_layout/logo.png") center center no-repeat;
    background-size: contain;
  }
}

.hamb-wrapper {
  cursor: pointer;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
  height: 42px;
  float: right;
}

.hamb-container {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0 10px 0 0;
  display: block;
  margin: auto;
  width: 42px;
  float: left;
  border-radius: 4px;
}

.hamb-line {
  position: relative;
  width: 100%;
  display: block;
  height: 4px;
  border-radius: 3px;
  margin: 0 0 7px 0;
  transition: transform 0.3s;
  background: $primary;
}

.hamb-line:nth-child(2) {
  width: 60%;
}

.hamb-line:last-child {
  margin-bottom: 0;
}

.hamb-wrapper p {
  position: relative;
  float: left;
  margin-left: -20px;
  display: inline-block;
  font-family: "Gotham-bold", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: $primary;
  line-height: 16px;
}

.wrapper-gal-prod {
  background: white;
}

.popup {
  padding-left: 0 !important;
}

.modal-xl img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: 800px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1300px;
  }
}

@media (max-width: 1200px) {
  .wrapp-noticia {
    width: 33.33333% !important;
  }

  .sob-title {
    margin-top: 1rem !important;
  }
}

@media (max-width: 1000px) {
  .col-foot-news-title, .col-foot-news {
    width: 100%;
    padding: 0;
  }

  .col-foot-news {
    margin-top: 20px;
  }

  form[name="newsletter"] .col-md-5 {
    width: 100% !important;
    flex: 1;
  }

  form[name="newsletter"] .col-md-5:nth-child(2) {
    padding-right: 0 !important;
  }

  .menu-footer {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .col-prod-esp-inf {
    max-height: 416px;
    overflow-y: scroll;
  }
}

@media (max-width: 992px) {
  .wrapper-prod {
    width: 325px;
  }

  .sob-home {
    padding-bottom: 50px !important;
  }

  .wrapp-noticia {
    width: 50% !important;
  }

  .col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;
    float: none !important;
  }

  .col-100 h1 {
    margin-top: 10px !important;
  }

  .prod-page .col-8, .prod-page .col-4, .modal-espiar .modal-body .col-8, .modal-espiar .modal-body .col-4 {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    padding: 0 !important;
  }

  .modal-espiar .modal-body .col-4, .prod-page .col-4 {
    margin-top: 25px;
  }

  .slider-img .badge {
    display: none !important;
  }

  .bar-filtros {
    padding: 15px 20px;
    background: transparent !important;
  }

  .bar-filtros nav {
    display: none !important;
  }

  .bar-filtros .open-sideright {
    display: block;
    margin: 0 auto;
    width: 350px;
    max-width: 100%;
  }

  .row-pag {
    display: block !important;
    width: 100% !important;
  }

  .title-art {
    font-size: 3rem !important;
    line-height: 50px !important;
  }

  .share-btns-arts {
    width: 100%;
  }

  .share-btns-arts > .row {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .share-btns-arts button {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .share-btns-arts h6 {
    text-align: center;
  }

  .row-m-conta .col-6:first-child {
    margin-bottom: 20px;
  }

  .row-m-conta .col-6 {
    width: 100%;
    flex: none !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .row-end {
    width: 100%;
    display: block !important;
  }

  .row-end > .col-8, .row-end > .col-4 {
    width: 100% !important;
    max-width: 100% !important;
    flex: none !important;
    padding: 0 !important;
  }

  .row-end > .col-8 {
    margin-top: 30px;
  }

  .checkout-title {
    width: 100%;
  }

  .checkout-header {
    padding: 0;
  }

  .wrapper-checkout-header nav {
    width: 100%;
    float: none;
  }

  .btn-status-checkout {
    width: 100%;
    float: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .btn-status-checkout .line-right {
    width: 7px;
    height: 12px;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .btn-status-checkout .line-left {
    display: none !important;
  }

  .line-r, .line-lr, .line-l {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .cv-wrapp-itens, .cv-wrapp-finalizar {
    width: 100%;
    padding: 0;
  }

  .cv-wrapp-finalizar {
    margin-top: 20px;
  }

  .cv-box-img {
    width: 45% !important;
  }

  .cv-box-infs {
    width: 55% !important;
  }

  .cv-box-options {
    width: 100%;
    padding: 0 !important;
  }

  .cv-box-img .col-auto {
    height: 120px !important;
  }

  .col-end-dados .row, .box-end-sel .row {
    flex-flow: wrap !important;
  }

  .col-end-dados .col-5, .col-end-dados .card-title {
    max-width: 100% !important;
    flex: none !important;
    width: 100% !important;
  }

  .row-pag .col-md-8, .row-pag .col-md-4 {
    width: 100%;
    max-width: 100%;
    flex: none;
    padding: 0;
  }

  .row-pag .col-md-4 {
    margin-top: 30px;
  }
}

@media (max-width: 1120px) {
  .col-text-sobre {
    width: 100% !important;
    border: 0 !important;
    padding: 0 !important;
    margin-bottom: 35px;
  }
  .col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 !important;
    float: none !important;
  }
}

@media (max-width: 770px) {
  .menu-footer {
    transform: translateX(20px);
  }

  .wrapper-prod {
    width: 375px;
    max-width: 100% !important;
  }

  .prods-rel {
    display: block !important;
    width: 100% !important;
  }

  .wrapp-noticia {
    width: 100% !important;
  }

  .modal-footer {
    flex-direction: column !important;
    align-items: unset;
  }

  .modal-footer button {
    margin-left: 0;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .modal-espiar .modal-footer .btn-add-cart {
    margin: 0 !important;
    width: 100% !important;
  }

  .modal-espiar .modal-footer .btn-link {
    margin-top: 10px !important;
    width: 100% !important;
  }

  .title-art {
    font-size: 2.7rem !important;
    line-height: 42px !important;
  }

  .row-identificacao .col-md-6 {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none;
  }

  .divider-right {
    border-bottom: 1px solid #e9ecef !important;
    padding-bottom: 30px !important;
  }

  .frete .row {
    flex-direction: column;
  }

  .frete .row button {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 20px !important;
  }

  .frete .row .col {
    max-width: 100%;
    flex-basis: auto !important;
    flex-grow: 1;
    margin-top: 15px;
    padding: 0 !important;
    text-align: center;
  }

  .pg-processado {
    display: block;
    text-align: center;
  }

  .pg-processado .text-muted {
    margin: 0 !important;
  }

  .pg-processado .text-muted:nth-child(2) {
    display: none !important;
  }

  .pg-processado img {
    margin-left: 0 !important;
    max-width: 80% !important;
  }

  .row-end-chk button {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }

  .form-area-to-send .col-md-6 {
    width: 100% !important;
    padding: 0;
    max-width: 100%;
    flex: none;
  }
}

@media (max-width: 480px) {
  .newsletter-text {
    line-height: 26px;
  }

  form[name="newsletter"] .col-md-5 {
    width: 100% !important;
    padding: 0 !important;
    flex: none !important;
  }

  .menu-footer {
    transform: none !important;
  }

  .menu-footer ul {
    width: 100% !important;
    flex: none !important;
    max-width: 100% !important;
    padding: 0;
  }

  h1 {
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .title-art {
    font-size: 2.5rem !important;
    line-height: 40px !important;
  }

  .row-prod-title {
    padding: 0 !important;
  }

  .row-prod-title p {
    width: 100% !important;
  }

  .row-prod-title .btn-absolute {
    position: relative;
    margin-top: 5px !important;
    left: 0;
    top: 0;
  }
}

@media (max-width: 460px) {
  .text-copy {
    font-size: 12px !important;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }

  .ctn-fot_rights {
    padding: 15px 0 !important;
    height: auto !important;
  }

  .item-group-galeria {
    height: 65px !important;
  }

  .slider-img {
    height: 316px !important;
  }

  .row-m-conta .card-title {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .row-m-conta .h2 {
    font-size: 0.95rem;
  }

  .row-m-conta .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .row-m-conta .icon-shape i {
    font-size: 1rem;
  }

  .cv-box-img .col-auto {
    height: 150px !important;
  }
}

@media (max-width: 395px) {
  .modal-espiar .modal-footer .btn-link {
    font-size: 10px !important;
  }

  .item-group-galeria {
    height: 48px !important;
  }

  .slider-img {
    height: 256px !important;
  }

  .title-art {
    font-size: 1.6rem !important;
    line-height: 28px !important;
  }

  .row-identificacao .btn-link {
    font-size: 10px !important;
  }

  .row-m-conta .card-title {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .row-m-conta .h2 {
    font-size: 0.75rem;
  }

  .cv-box-img, .cv-box-infs {
    margin-bottom: 15px;
    padding: 0 !important;
    width: 100% !important;
  }

  .row-title-pag {
    flex-direction: column;
  }

  .row-title-pag .col-auto {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .row-title-pag .col {
    max-width: 100%;
    flex-basis: auto;
    flex-grow: 1;
  }

  .box-tipo-mob {
    width: 100% !important;
    max-width: 100%;
    flex: none !important;
    border: none !important;
  }
}

.nav-item {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.nav-item .nav-link:hover {
  color: #da6d00 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 20px !important;
}

.input-group-alternative {
  background: transparent;
  border: 1px solid $primary;
}

.input-group-text i {
  color: $primary;
}

form[name="newsletter"] input {
  color: $primary !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="newsletter"] input::placeholder {
  color: $primary !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="contato"] input, form[name="contato"] textarea {
  color: $primary !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

form[name="contato"] input::placeholder, form[name="contato"] textarea::placeholder {
  color: $primary !important;
  font-family: "Geomanist", sans-serif;
  font-size: 16px;
}

.wrpp-its_top {
  position: absolute;
  bottom: 50px;
  left: 200px;
}

.box-it-feira {
  position: relative;
  display: block;
  flex: 1;
  margin: 10px 20px;
  width: 240px;
  height: 315px;
}

.content-it-feira {
  position: relative;
  z-index: 2;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  border: 3px solid #b2b2b2;
  text-align: center;
  padding: 60px;
  background: #f2f2f2;
  width: 100%;
  height: 100%;
}

.content-it-feira img {
  margin-bottom: 45px;
}

.content-it-feira img.w {
  display: none;
}

.content-it-feira p {
  font-family: "oxygenbold", sans-serif;
  color: #0f0f0f;
  font-size: 17px;
  margin-bottom: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-back-feira {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 70%;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 6px solid #fe8100;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.box-it-feira:hover img {
  display: none;
}

.box-it-feira:hover img.w {
  display: inline-block;
}

.box-it-feira:hover .content-it-feira {
  background: #fe8100;
  border-color: #fe8100;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-it-feira:hover p {
  color: white;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-it-feira:hover .box-back-feira {
  bottom: -10px;
  width: 90%;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

button {
  font-size: 16px !important;
  padding: 0.35rem 1.85rem !important;
}

.box-it-show {
  margin: 15px;
  cursor: pointer;
}

.box-it-show h3 {
  text-align: center;
  font-family: "bebas_neueregular", sans-serif;
  letter-spacing: 1px;
  font-size: 32px;
  color: white;
}

.content-it-show {
  border-radius: 12px;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  overflow: hidden;
  width: 220px;
  height: 288px;
  background: #fe8100;
  margin: auto;
}

.content-it-show:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

.img-show {
  width: 100%;
  height: 240px;
  position: relative;
  background: rgba(205, 123, 41, 0.49);
}

.img-show i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  font-size: 58px;
}

.content-it-show p {
  padding: 15px;
  color: white;
  font-size: 11px;
  text-align: justify;
}

.data-show {
  font-size: 20px !important;
  margin-bottom: 0 !important;
  text-align: center !important;
  background: rgba(228, 136, 46, 0.58);
}

.wrapp-noticia {
  width: 25%;
  display: block;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}

.box-noticia {
  text-decoration: none !important;
  display: block;
  position: relative;
  width: 100%;
  height: 428px;
  background: white;
}

.head-not {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.img-not {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: 0.15s;
}

.box-noticia .title-not {
  position: absolute;
  z-index: 3;
  font-size: 18px;
  margin-bottom: 0;
  color: white;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px 15px;
}

.content-not {
  position: relative;
}

.post-infs-not {
  text-align: left;
}

.post-infs-not {
  font-family: "Geomanist", sans-serif;
  padding: 10px 15px;
  margin-bottom: 0;
  font-weight: 300;
  color: #a5a5a5;
  font-size: 12px;
}

.post-infs-not span {
  float: right;
  font-size: 10px !important;
}

.wrapp-noticia .text-not {
  position: absolute;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  left: 0;
  top: 37px;
}

.box-noticia .text-not {
  padding: 0 15px 15px 15px;
}

.head-not:after {
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.95) 100%);
  content: "";
  opacity: 0.95;
  transition: 0.15s;
}

.box-noticia:hover .head-not:after {
  opacity: 1;
  height: 60%;
  transition: 0.3s;
}

.box-noticia:hover .img-not {
  transform: translate(-50%, -50%) scale(1.12);
  -webkit-transform: translate(-50%, -50%) scale(1.12);
  transition: 0.3s;
}

.inTop .nav-link {
  height: 100%;
}

.inTop .box-logo {
  width: 100px;
  text-align: center;
}

.box-logo-patr {
  width: 100%;
  height: 200px;
}

.lk-menu {
  color: #616161 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  padding-top: 0 !important;
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  padding-bottom: 0 !important;
  font-family: "oxygenbold", sans-serif;
  font-size: 15px !important;
  letter-spacing: 3px !important;
  text-transform: uppercase;
}

.lk-menu:not(.lk-menu-active):hover {
  color: #fc9733 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.lk-menu-active {
  color: #fe8100 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

//.box-top-soberanas {
//  position: relative;
//  background: url("../img/_layout/fundo_soberanas.png") repeat-x;
//}
//
//.box-top-soberanas span {
//  display: block;
//  width: 100px;
//  height: 100%;
//  background: url("../img/_layout/fundo_soberanas.png") repeat-x;
//  position: absolute;
//  right: 100%;
//  top: 0;
//}

.box-foto:not(.i-bann) {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  width: 257px;
  height: 282px;
  box-shadow: 0 8px 20px 2px rgba(0, 0, 0, 0.15);
}

.box-foto:hover .overlay-foto {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.box-foto:hover .foto {
  -webkit-transform: translate(-50%, -50%) scale(1.2);
  -moz-transform: translate(-50%, -50%) scale(1.2);
  transform: translate(-50%, -50%) scale(1.2);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.overlay-foto {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  width: 120px;
  height: 120px;
  position: absolute;
  padding: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.55);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.overlay-foto p i {
  font-size: 32px;
  margin-bottom: 10px;
}

.overlay-foto p {
  margin-bottom: 0;
  font-size: 13px;
  color: white;
}

.overlay-foto div {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.foto {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  transition: 0.2s;
}

#fancybox-thumbs {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 8050;
}

#fancybox-thumbs.bottom {
  bottom: 2px;
}

#fancybox-thumbs.top {
  top: 2px;
}

#fancybox-thumbs ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

#fancybox-thumbs ul li {
  float: left;
  padding: 1px;
  opacity: 0.5;
}

#fancybox-thumbs ul li.active {
  opacity: 0.75;
  padding: 0;
  border: 1px solid #fff;
}

#fancybox-thumbs ul li:hover {
  opacity: 1;
}

#fancybox-thumbs ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #222;
  background: #111;
  outline: none;
}

#fancybox-thumbs ul li img {
  display: block;
  position: relative;
  border: 0;
  padding: 0;
  max-width: none;
}

.artigos-list .wrapp-noticia {
  width: 33.3333333%;
}

.table .thead-light th {
  color: white;
  background-color: #fc9733;
  font-size: 0.95rem;
}

.table td {
  font-size: 0.9rem !important;
}

.nav-wrapper .nav-link {
  font-family: "oxygenlight", sans-serif;
  font-weight: 100;
  text-transform: none !important;
}

.nav-pills .nav-link:hover {
  color: #687584 !important;
  background-color: #eaeaea !important;
}

.nav-pills .nav-link.active:hover, .nav-pills .show > .nav-link:hover {
  color: #fff !important;
  background-color: #da6d00 !important;
}

.it-show-pg {
  position: relative;
}

.badge-primary {
  color: #da6d00;
  margin-bottom: 20px;
  font-size: 15px;
}

// inicio css site

$primary: #3D3F92;
$primarylight: #3D3F92;
$secondary: #BD3B43;
$secondarydark: #7c272f;
$primarydark: #323264;
$colorfundotitles: #6B696C;
$errorcolor: red;

body { font-family: 'akrobatregular', sans-serif !important; overflow-x: hidden; background: $primary; }
h1, p, span, h2, h3 { font-family: 'akrobatregular', sans-serif !important; }

.foot {
  background: $primary;
}

.ctn-fot_rights {
  background: $secondary;
  color: white !important;
  padding: 20px 0;
}
.text-copy {
  color: white !important;
}

.text-contato {
  text-align: center; font-size: .75rem;
  color: $colorfundotitles;
  //@include transform(translateY(-50px));
  font-family: 'akrobatregular', sans-serif;

  a {
    font-family: 'akrobatbold', sans-serif;
    font-size: 18px;
    line-height: 20px;
    color: $colorfundotitles;
  }
  span {
    display: block;
    font-size: 30px;
    line-height: 35px;
    b {
      font-family: 'akrobatblack', sans-serif;
    }
  }
}

.btn-yellow {
  background: $secondary;
  color: #fff;
  padding: 0.50rem 2.85rem !important;

  &:before {
    position: absolute;
    content: ' ';
    display: block;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%,-50%));
    @include transition(.3s);
    background: #ffff39;
    box-shadow:
            0 0 14px 10px #ffff39, 0 0 20px 10px #ffff39, 0 0 65px 13px #FFFF3A;
    width: 70%;
    border-radius: 50%;
    height: 50%;

  }
  &:hover {
    background: $secondary;
    color: #fff;
    &:before {
      box-shadow:
              0 0 18px 12px #ffff39, 0 0 20px 10px #ffff39, 0 0 70px 20px #FFFF3A;
      @include transition(.3s);
    }
  }
  &:active {
    border-color: $secondary;
    background: $secondarydark !important;
    &:before {

      @include transition(.3s);
    }
  }
  span {
    position: relative;
    font-family: 'bebas_neueregular', sans-serif;
    color: black;
    font-size: 1.3rem;
  }
}

header {
  section {
    position: relative;
    z-index: 1;
  }
}

.ctn-top-header {
  background-color: #F2F0F1;
  height: 105px;
  border-top: none;
  z-index: 4;
  .header-infs {
    background: black;
    padding: 3px 0;
    p {
      display: inline-block;
      margin: 0 !important;
      font-size: 14px;
      color: white;
      &:first-child {
        margin-right: 20px !important;
      }
    }
  }
  .navbar-horizontal {
    width: 100%;
    height: calc(100% - 34px);
  }
  .nav-item {
    position: relative;
    padding-right: .8rem !important;
    padding-left: .8rem !important;
    //&:before {
    //  position: absolute;
    //  content: ' ';
    //  display: none;
    //  z-index: 1;
    //  left: 50%;
    //  top: 50%;
    //  @include transform(translate(-50%,-50%) skew(-31deg, 0deg));
    //  @include transition(.3s);
    //  width: 0;
    //  height: 120%;
    //  background: $primarydark;
    //}
  }
  .nav-item.active:before {
    width: 100%;
    @include transition(.3s);
  }
  .nav-link {
    position: relative;
    z-index: 2;
    line-height: 76px;
    color: $colorfundotitles !important;
    &:hover {
      color: $secondary !important;
    }
  }
  .box-logo {
    position: relative;
    width: 165px;
    height: 100%;
    @include transition(.4s);
  }
}

.btn-rs {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 20px;
  height: 20px;
  background: white;
  color: black;
  border-radius: 50%;
  .fab {
    position: absolute;
    @include center();
  }
  &:hover {
    background: $secondary;
    color: white !important;
    @include transition(.3s);
  }
}

.ctn-banner {
  background: $primarylight;
}

.inTop {
  .box-logo {
    width: 215px !important;
    @include transition(.4s);
  }
  .box-logo img {

  }
}

.float-btn:hover {
  transition: 0.3s;
  background: $secondary;
}
.wrapper-lines-up span {
  background-color: $secondary;
}

.cat-home {
  width: 380px;
  padding: 0 40px;
  margin-bottom: 60px;
  div:first-child {
    padding-left: 45px;
  }
  div:nth-child(2) {
    position: relative;
    height: 328px;
  }
  img {
    position: absolute;
    max-width: 100%;
    top: 50%; left: 50%;
    @include transform(translate(-50%,-50%));
  }
  p {
    color: white;
    font-family: 'bebas_neueregular', sans-serif;
    text-align: justify;
    font-size: 14px;
  }
  button {
    margin-top: 30px;
  }
}
.title-tip-1 {
  font-family: 'akrobatextrabold', sans-serif !important;
  font-size: 60px !important;
  color: $colorfundotitles;
  text-transform: uppercase;
  position: relative;
  &:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 200px;
    height: 5px;
    background: $colorfundotitles;
    max-width: 70%;
    left: 50%;
    bottom: 5px;
    @include transform(translateX(-50%));
  }
  &.white {
    color: white !important;
  }
  &.white:before {
    background: white !important;
  }
}

#map {
  height: 475px;
}

.subtitle-tip-2 {
  font-family: 'bebas_neuelight', sans-serif;
  display: inline-block;
  font-size: 34px !important;
  color: white;
}

.sob-home {
  p {
    color: $colorfundotitles;
    text-align: center;
    font-size: 20px;
    margin-top: 25px;
    line-height: 25px;
  }
}

.input-disabled {
  background: rgba(236, 239, 241, 0.16) !important;
}

.prod-head {
  margin-bottom: 0;
  border: 6px solid $primary;
}
.wrpp-prod_infs {
  background: #ececec;
  padding: 15px 20px;
  height: 130px;
}

.nome-prod {
  font-family: 'poppinsregular', sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
  color: $primary;
}
.desc-prod {
  font-family: 'akrobatbold', sans-serif;
  font-size: 14px;
  text-align: left;
  color: black;
}

.wrapper-prod {
  width: 255px;
}
a {
  color: $secondary;
  @include transition(.3s);
  &:hover {
    color: $secondarydark;
    @include transition(.3s);
  }
}
.sidemenuright-header {
  font-family: "poppinsregular", Sans-Serif;
  background: $primary !important;
}
.content-sidemenu .dropdown-item {
  color: $primary !important;
  font-family: "poppinsregular", Sans-Serif;
}

.breadcrumb {
  margin-bottom: 2.0rem;
}
.page-link {
  background: transparent;
  color: white;
  &:hover {
    color: $primary;
  }
}

.page-item.active .page-link {
  border-color: $secondary;
  background-color: $secondary;
  color: white !important;
}

.page-item.disabled .page-link {
  pointer-events: none;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.12);
}

.bar-filtros {
  background: transparent !important;
  width: 100%;
  border-radius: 6px;
}

.title-filter {
  font-family: 'poppinsregular', sans-serif;
  position: relative;
  padding: 3px 30px 3px 0;
  text-align: right;
  color: white;
  &:before {
    position: absolute;
    content: ' ';
    display: block;
    right: 0;
    top: 0;
    width: 15px;
    background: $secondary;
    height: 100%;
  }
}

.item-filter {
  padding: 4px 0;
  text-align: right;
  color: white;
}


.btn-primary {
  background: $primary;
  border: 2px solid transparent !important;
  .btn-inner--text {
    font-family: 'poppinslight', sans-serif !important;
    margin-left: .25em !important;
  }
  &:hover {
    background: $primary;
  }
  &:active {
    background: $primarydark !important;
    border-color: $primary !important;
  }
}

.btn-white {
  background: white;
  border: 2px solid transparent !important;
  font-family: 'akrobatbold', sans-serif !important;
  font-weight: bold;
  color: $primary !important;
  font-size: 1.8rem !important;
  .btn-inner--text {
    font-family: 'akrobatbold', sans-serif !important;
    margin-left: .25em !important;
  }
  &:hover {
    background: $white;
  }
  &:active {
    background: $primarydark !important;
    border-color: $primary !important;
    color: white !important;
  }
}

.btn-secondary {
  background: $secondary;
  border: 2px solid transparent !important;
  color: white !important;
  font-family: 'akrobatbold' !important;
  font-weight: bold;
  font-size: 25px;
  .btn-inner--text {
    margin-left: .25em !important;
  }
  &:hover {
    background: $secondary;
  }
  &:active {
    background: $secondarydark !important;
    border-color: $secondary !important;
  }
}

.btn-dark {
  background: rgba(white, 0.15);
  border: 2px solid transparent !important;
  .btn-inner--text {
    font-family: 'poppinslight', sans-serif !important;
    margin-left: .25em !important;
  }
  &:hover {
    background: rgba(white, 0.15);
  }
  &:active {
    background: rgba(white, 0.25) !important;
    border-color: rgba(white, 0.15) !important;
  }
}

.title-prod { font-family: 'poppinsregular', sans-serif; text-transform: uppercase; font-size: 1.6rem; }

.badge-codigo {
  background: rgba(white, 0.15);
  border: 2px solid transparent !important;
  font-family: 'poppinslight', sans-serif;
  font-size: 0.8rem;
  letter-spacing: 2px;
  span {
    font-family: 'poppinslight', sans-serif;
    font-size: 0.8rem;
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: $secondary;
  background-color: $secondary;
}

.wrapper-gal-prod {
  background: transparent !important;
}

.slider-img .badge {
  color: white !important;
  background: rgba(white, 0.15);
  padding: 0.65rem 0.675rem;
}

.it-min-sel {
  opacity: 1;
  .img-galeria-d {
    opacity: 0.4;
  }
}

.slider-img-group {
  .item-group-galeria {
    background: white !important;
  }
  .img-galeria-d {
    max-width: 85% !important;
    max-height: 85% !important;
  }
}
.item-galeria-d {
  background: white !important;
}

.slider-img {
  padding-left: 25px;
}

.nav-albuns {
  .nav-link.active {
    color: $primary !important;
    @include transition(.3s);
  }
  .nav-link {
    line-height: 50px !important;
    font-size: 18px !important;
    font-family: 'akrobatbold' !important;
    color: #8898aa;
    &:hover {
      color: $primary !important;
      @include transition(.3s);
    }
  }
}

.nanoGalleryContainer   { background: transparent !important; }
.nanoGalleryThumbnailContainer  { border: none !important; background: transparent !important; }
.labelImage     { display: none; background: url('../img/_layout/lup_lj.png') center 30% rgba(234, 207, 50, 0.8) no-repeat !important; height: 96% !important; }
.labelImageTitle { display: none !important; }
.labelDescription   { text-align: center !important; top: 65% !important; position: relative !important; font-family: 'poppinsregular', sans-serif !important; font-size: 18px !important; font-weight: bold; text-shadow: none !important; text-transform: uppercase; color: white !important; }

.modal-title {
  color: white;
}

.modal-content {
  background: rgb(47, 47, 47);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 35px rgba(0, 0, 0, 0.807) !important;
}

.modal-footer {
  border: none !important;
  background: rgb(37, 37, 37) !important;
}

.modal-footer {
  .btn-link {
    color: white;
    @include transition(.3s);
    &:hover {
      color: rgba(white, 0.4);
      @include transition(.3s);
    }
  }
}

.slick-track,.slick-list { outline: none !important; }
.i-bann {
  position: relative;
  display: block;
  padding: 40px;
  .img-banner {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .row {
    width: 100%; height: 100%;
    justify-content: center;
    align-items: center;
  }
  .title-banner {
    font-family: 'bebas_neuebold', sans-serif;
    font-size: 7.4rem;
    line-height: 5.5rem;
  }
  .subtitle-banner {
    font-family: 'bebas_neuebold', sans-serif;
    font-size: 3rem;
    line-height: 2.4rem;
    color: white;
  }
  .text-banner {
    color: white;
    font-family: 'bebas_neueregular', sans-serif;
    text-align: justify;
    font-size: 14px;
  }
  .col-auto { position: relative; padding: 0 !important; }
  .col-auto:first-child {
    z-index: 1;
    margin-right: -25px;
  }
  .col-auto:nth-child(2) {
    z-index: 2;
  }
  .col-auto:last-child {
    max-width: 300px;
    z-index: 1;
    margin-left: -25px;
  }
}

@media (max-width: 680px) {
  //.ctn-banner {
  //  display: none !important;
  //}
}

.alert-dismissible .close { top: 50% !important; }

.box-servico {
  background: #18235B;
  border: 1px solid $primary;
  padding-top: 30px;
  padding-bottom: 40px;
  cursor: pointer;
  @include transition(.3s);
  &:hover {
    background: #3A437E;
    @include transition(.3s);
  }
  p {
    color: white;
    font-family: 'akrobatbold';
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0 0 0 !important;
    display: block;
    text-align: center;
  }
}

.box-imgs-sobre {
  position: relative;
  background: transparent !important;
  //width: 545px;
  margin-top: 25px;
  border: 4px solid white;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.3);
  .box-imgs-sobre-slider {
    position: static;
    height: 100%;
  }
}
.box-imgs-sobre {
  width: 100%;
  height: 400px;
  background: #aea5a0;
}

.box-marca {
  width: 134px;
  height: 120px !important;
  padding: 15px 30px;
  margin: 15px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.30);
  background-position: center center;
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
}

.marcas-slider {
  .slick-dots {
    bottom: -60px !important;
  }
}

.box-infs {
  padding: 15px 0;
  background: white;
}

.box-info {
  position: relative;
  width: 100%;
  background: $primary;
  padding: 10px;
  .wrapper-info {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid white;
    min-height: 245px;
  }
  .bottom-info {
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    height: 38px;
    .ic {
      position: absolute;
      background: white;
      height: 40px;
      width: 40px;
      bottom: 0;
      left: 50%;
      @include transform(rotate(45deg) translateX(-50%));
      div {
        width: 100%;
        height: 100%;
        @include transform(rotate(-45deg));
      }
      .ic-contato {
        background: url('../img/_layout/email_icon.png') center center no-repeat;
      }
      .ic-map {
        background: url('../img/_layout/mapa_site_icon.png') center center no-repeat;
      }
      .ic-local {
        background: url('../img/_layout/local_icon.png') center center no-repeat;
      }
      .ic-fones {
        background: url('../img/_layout/fone_icon.png') center center no-repeat;
      }
    }
  }
  .subtitle-tip-2 {
    width: 100%;
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 1.65rem !important;
    font-family: 'akrobatblack' !important;
  }
  .btn-rs {
    background: black;
    color: white;
    width: 30px;
    height: 30px;
    @include transition(.3s);
    &:hover {
      background: white;
      color: black !important;
      @include transition(.3s);
    }
  }
  p {
    color: white;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.6rem;
  }
  .lk-in {
    display: block;
    color: white;
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    @include transition(.3s);
    &:hover {
      color: rgba(255,255,255,0.7);
      @include transition(.3s);
    }
  }
}

.title-bar {
  padding: 35px 0;
  h1 {
    font-family: 'akrobatblack' !important;
    color: $primary !important;
    text-transform: uppercase;
    text-align: center;
    font-size: 3.4rem;
  }
}
.content-ajax {
  margin: 0 !important;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $primary;
  padding: 50px 0 90px 0;
  display: none;
}


.shine {
  background: #8489ff;
  background-image: linear-gradient(to right, #8489ff 0%, #8489ff 20%, #6060d0 40%, #8489ff 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

photo {
  display: block!important;
  width: 100%;
  height: 400px;
  margin-top: 15px;
}

box {
  height: 104px;
  width: 100px;
  border-radius: 10px;
}


lines {
  height: 15px;
  border-radius: 8px;
  margin-top: 15px;
  width: 100%;
}

h1.shine {
  height: 30px;
  border-radius: 8px;
  margin-top: 15px;
  width: 100%;
}


@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.title {
  display: inline-block;
  font-family: 'akrobatblack' !important;
  font-size: 4.0rem;
  text-transform: uppercase;
  color: white;
  text-align: center;
  width: auto;
  line-height: normal !important;
}
.icone-servico {
  display: inline-block;
  width: 150px;
  height: 85px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.img-servico {
  width: 100%;
  margin: 25px 0;
  background-color: rgba(255,255,255,0.25);
}
.text-servico {
  text-align: center;
  color: white !important;
  margin-top: 30px;
  p {
    color: white !important;
  }
}

.close-modal {
  background-color: transparent;
  cursor: pointer;
  height: 65px;
  position: absolute;
  right: 30px;
  top: 25px;
  width: 65px;
}
.lr {
  background-color: #fff;
  height: 50px;
  margin-left: 35px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 2px;
  z-index: 1051;
}
.lr .rl {
  background-color: #fff;
  height: 50px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  width: 2px;
  z-index: 1052;
}
.box-artigos-slider {
  position: relative;
  border: 4px solid white;
  width: 100%;
  height: 400px;
  box-shadow: 0 4px 10px rgba(50, 50, 93, 0.11), 0 3px 4px rgba(0, 0, 0, 0.3);
  .slick-dots {
    bottom: 0;
  }
  .box-artigo-slider {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .text-artigo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 15px 20px 45px 15px;
    background: rgba(0,0,0,0.5);
    h2 {
      text-align: center;
      color: white;
      text-transform: uppercase;
      font-family: 'akrobatblack' !important;
      font-size: 1.7rem;
    }
  }
}

.box-artigo-destaque {
  width: 100%;
  height: 170px;
  display: block;
  h3 {
    color: white;
    font-family: 'akrobatblack' !important;
    font-size: 1.15rem;
  }
  span {
    color: white;
    font-size: .70rem;
  }
  p {
    color: rgba(white, .8);
    font-family: 'akrobatregular' !important;
    text-transform: uppercase;
    font-size: .75rem;
  }
  &:first-child {
    margin-bottom: 45px;
  }
  .box-img {
    width: 100%;
    height: 170px;
    border: 3px solid white;
    background: url('../img/_layout/sem_foto.png') center center no-repeat;
    background-size: 80px;
  }
}
.fotos-albuns-resp { display: none !important; height: 212px !important; }
@media (max-width: 780px) {
  .icone-servico {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .title-tip-1 {
    font-size: 40px !important;
    line-height: 42px;
    text-align: center;
    &:before {
      display: none !important;
    }
  }
  .box-artigo-destaque {
    height: auto !important;
  }
  .box-artigo-destaque:first-child {
    margin-bottom: 15px !important;
  }
  .fotos-albuns-resp { display: block !important; }
  .fotos-albuns { display: none !important; }
}

.ctn-top-header .nav-item {
  position: relative;
  &:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 0;
    background: $secondary;
    height: 4px;
    border-radius: 2px;
    bottom: 10px;
    left: 50%;
    @include transform(translateX(-50%));
    @include transition(.3s);
  }
  &.active {
    .nav-link { color: $secondary !important; }
    position: relative;
    &:before {
      @include transition(.3s);
      width: 90%;
    }
  }
}

.fotos-albuns {
  text-align: center;
  width: 100%;
  .img-album {
    position: relative;
    display: inline-block;
    margin: 10px;
    width: 250px;
    height: 220px;
    overflow: hidden;
    border: 4px solid white;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
    @include transition(.3s);
    &:hover {
      @include transition(.3s);
      .alt-img {
        top: 0;
        @include transition(.3s);
      }
    }
    .alt-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 100%;
      background: rgba($primary, .8);
      text-align: center;
      color: white;
      font-size: 17px;
      @include transition(.3s);
      span {
        position: absolute;
        font-family: 'Gotham-bold';
        font-weight: bold;
        text-shadow: 0px 1.5px 2px black;
        @include center();
      }
    }
  }
}

.fancybox-overlay, #fancybox-thumbs {
  z-index: 999999999 !important;
}

